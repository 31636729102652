import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router'
import { clearStorage, isPermission } from '../../otherServices/commonFunction'
import { Alert, Modal } from 'antd';

const SESSION_IDEL_MINUTES = 60;

const AutoLagoutTimer = (props) => {
  const { ComposedClass } = props
  const history = useHistory()

  const handleOnIdle = (event) => {
    // SHOW YOUR MODAL HERE AND LAGOUT
    
    alert("Current session has expired. Please Login again");
    setTimeout(function () { history.push("/login") }, 2000);
    clearStorage()

  }

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return <ComposedClass />
}

export default AutoLagoutTimer;