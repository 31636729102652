import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import moment from 'moment'
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  message,
  Spin,
  Space,
} from 'antd'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import './CatalogTableEdit.scss'
import { HTTPService } from '../../otherServices/apiService'
import {
  DATE_FORMAT,
  CATALOG_SORTING,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import CatalogForm from './CatalogForm'
import {
  exportCSVFile,
  downloadExcel,
  isPermission,
} from '../../otherServices/commonFunction'
import { ExportToCsv } from 'export-to-csv'


let { Search } = Input
let selectedCatalogs = []

const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async (e) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 20,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class EditableTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  //search function for table columns
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div className="search_filterDropdown">
        <Input
          className="search_input"
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            clearFilters()
            this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
          }}
        />
        <Space className="btns">
          <Button
            type="primary"
            onClick={() => {
              clearFilters()
              this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
            }}
            icon={<SearchOutlined />}
            size="medium"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              this.onFilterReset(`${dataIndex}`)
            }}
            size="medium"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#3653AA' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#fafafa', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '', filterValueArray: [] })
  }

  //table column properties
  constructor(props) {
    super(props)

    this.state = {
      dataSource: null,
      count: 0,
      currentPage: 1,
      tableData: [],
      isFormOpen: false,
      currentCatalog: null,
      loader: false,
      searchText: null,
      filterApplied: null,
      isAddPart: false,
      filterValueArray: [],
      isFilter: false,
      pageLimit: 20,
      filterString: '',
      selectedCatalog: [],
      selectAllExportData: [],
      exportURL: '',
      highlightFilterArray: [],
    }
  }

  componentDidMount() {
    if (this.state.isFilter) {
      this.getFilteredData(this.state.filterString)
    } else {
      this.fetchData()
    }
  }

  //function to fetch table data
  fetchData = async (searchValue = '', currentPage = 1) => {
    let offset = (currentPage - 1) * this.state.pageLimit
    this.setState({ loader: true, highlightFilterArray: [] })
    try {
      let res = await HTTPService({
        method: 'GET',
        url: `catalog/?${CATALOG_SORTING}&limit=${this.state.pageLimit}&${
          searchValue && searchValue
        }`,
      })
      if (res.success) {
        let catelogs = []
        for (let catalog of res.data.results) {
          catelogs.push({ ...catalog, key: catalog.id })
        }
        this.setState({
          dataSource: catelogs,
          count: res.data.count,
          loader: false,
          currentPage,
          searchText: searchValue || null,
          isFilter: false,
          filterValueArray: [],
          selectedCatalog: [],
        })
        message.success('Fetched Successfully')
        // this.getExportData()
      } else {
        message.error(res.message)
        this.setState({
          dataSource: [],
          count: 0,
          loader: false,
          searchText: searchValue || null,
          currentPage,
        })
      }
    } catch {
      this.setState({
        dataSource: [],
        count: 0,
        loader: false,
        searchText: searchValue || null,
        currentPage,
      })
      message.error('Failed to Fetch')
    }
  }

  //function to fetch filtered table data
  async getFilteredData(searchValue = '', currentPage = 1) {
    let urlLink = `catalog/?${CATALOG_SORTING}&${searchValue}`
    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let catelogs = []
        for (let o of userRes.data.results) {
          catelogs.push({ ...o, key: o.id })
        }
        this.setState({
          dataSource: catelogs,
          count: userRes.data.count,
          loader: false,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
        })
        message.success('Filter Applied')
        // this.getExportDataFiltered()
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Filter Not Applied')
    }
  }

  //search function
  onSearch = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      let stringSplit = value.split('=')
      if (i.includes(stringSplit[0])) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    arrayOfFilter.push(value)
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      filterString: `${arrayOfFilter.join('&&')}`,
      highlightFilterArray: highlightArray,
    })
    this.getFilteredData(`${arrayOfFilter.join('&&')}`)
  }

  //function to reset the applied filter
  onFilterReset = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      if (i.includes(value)) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      highlightFilterArray: highlightArray,
    })
    if (arrayOfFilter.length == 0) {
      this.fetchData('')
    } else {
      this.setState({ filterString: `${arrayOfFilter.join('&&')}` })
      this.getFilteredData(`${arrayOfFilter.join('&&')}`)
    }
  }

  handleDelete = async (catelog) => {
    try {
      await HTTPService({
        url: `catalog/${catelog.id}/`,
        method: 'DELETE',
      })
      message.success('Record Deleted')
      this.fetchData('', this.state.currentPage)
    } catch (ex) {
      if (ex.response.status === 500) message.error('Internal Server Error')
    }
  }

  handleChange = (value, keyName, record, isDate = false) => {
    if (isDate) {
      record[keyName] = moment().format(DATE_FORMAT)
      return
    }
    record[keyName] = value
  }

  //function to toggle flag to open Add/Edit form
  setFormStatus = (catalog, mode) => {
    if (mode == 'edit') {
      this.setState({ isAddPart: false })
    } else {
      this.setState({ isAddPart: true })
    }
    this.setState({
      isFormOpen: !this.state.isFormOpen,
      currentCatalog: catalog ? catalog : null,
    })
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.id === item.id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData,
    })
  }

  //function to fetch data fot table on page change
  onChangePageNumber = async (pageNumber, PAGE_LIMIT) => {
    this.setState({ loader: true, pageLimit: PAGE_LIMIT, selectedCatalog: [] })
    let offset = (pageNumber - 1) * PAGE_LIMIT
    try {
      let catelogRes
      if (this.state.isFilter) {
        let searchText = this.state.searchText
        catelogRes = await HTTPService({
          method: 'GET',
          url: `catalog/?${CATALOG_SORTING}&${searchText}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      } else {
        catelogRes = await HTTPService({
          method: 'GET',
          url: `catalog/?${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      }
      if (catelogRes.success) {
        let catelogs = [],
          count = 0
        for (let catelog of catelogRes.data.results) {
          catelogs.push({ ...catelog, key: catelog.id })
        }

        count = catelogRes.data.count
        this.setState({
          dataSource: catelogs,
          count,
          currentPage: pageNumber,
          currentCatalog: null,
          isFormOpen: false,
          loader: false,
        })
      } else {
        message.error(catelogRes.message)
        this.setState({
          isFormOpen: false,
          loader: false,
        })
      }
    } catch {
      message.error('Failed to fetch')
      this.setState({
        isFormOpen: false,
        loader: false,
      })
    }
  }

  //function to display total item
  showTotal = () => {
    return `Total ${this.state.count}`
  }

  exportData = () => {
    if (this.state.isFilter) {
      this.getExportDataFiltered()
    } else {
      this.getExportData()
    }
  }

  //function to export all data
  async getExportDataFiltered() {
    let urlLink = `export/catalog-xls/?${this.state.searchText}`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      
    }
  }

  async getExportData() {
    let urlLink = `export/catalog-xls/`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      
    }
  }

  //Delete catalog data
  
  handleDeleteCatalog = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let dataObj = {
        catalog_ids: this.state.selectedRoreOrder,
      }
      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `delete-catalog/`,
          data: {
            ...dataObj,
          },
        })
        if (userRes.success) {
          message.success('Delete Successfully.')
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        }
      } catch (error) {
        message.error('Delete Failed')
        this.setState({ loader: false })
        this.getOrderList('', this.state.currentPage)
      }
    } else message.info('Select orders to Delete')
  }

  render() {
    this.columns = [
      /* {
        title: 'Catalog_Ids',
        dataIndex: 'id',
        width: 200,
        ...this.getColumnSearchProps('id'),
        className: this.state.highlightFilterArray.includes('id')
          ? 'filtered_text'
          : '',
      }, */
      {
        title: 'MPN',
        dataIndex: 'mpn',
        width: 200,
        ...this.getColumnSearchProps('mpn'),
        className: this.state.highlightFilterArray.includes('mpn')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company of origin',
        dataIndex: 'company_of_origin',
        width: 200,
        ...this.getColumnSearchProps('company_of_origin'),
        className: this.state.highlightFilterArray.includes('company_of_origin')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Business Unit',
        dataIndex: 'business_unit',
        width: 200,
        ...this.getColumnSearchProps('business_unit'),
        className: this.state.highlightFilterArray.includes('business_unit')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Division',
        dataIndex: 'division',
        width: 200,
        ...this.getColumnSearchProps('division'),
        className: this.state.highlightFilterArray.includes('division')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Product Family',
        dataIndex: 'prod_family',
        width: 200,
        ...this.getColumnSearchProps('prod_family'),
        className: this.state.highlightFilterArray.includes('prod_family')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        width: 200,
        ...this.getColumnSearchProps('desc'),
        className: this.state.highlightFilterArray.includes('desc')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Can Sample',
        dataIndex: 'can_sample',
        width: 200,
        ...this.getColumnSearchProps('can_sample'),
        className: this.state.highlightFilterArray.includes('can_sample')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Market Family',
        dataIndex: 'mark_family',
        width: 200,
        ...this.getColumnSearchProps('mark_family'),
        className: this.state.highlightFilterArray.includes('mark_family')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Itar',
        dataIndex: 'itar',
        width: 200,
        ...this.getColumnSearchProps('itar'),
        className: this.state.highlightFilterArray.includes('itar')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'ECCN',
        dataIndex: 'eccn',
        width: 200,
        ...this.getColumnSearchProps('eccn'),
        className: this.state.highlightFilterArray.includes('eccn')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Life Cycle',
        dataIndex: 'life_cycle',
        width: 200,
        ...this.getColumnSearchProps('life_cycle'),
        className: this.state.highlightFilterArray.includes('life_cycle')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Fullfill Allowed',
        dataIndex: 'disti_fulfill_allowed',
        width: 200,
        ...this.getColumnSearchProps('disti_fulfill_allowed'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes(
          'disti_fulfill_allowed'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Export Controlled',
        dataIndex: 'export_controlled',
        width: 200,
        ...this.getColumnSearchProps('export_controlled'),
        className: this.state.highlightFilterArray.includes('export_controlled')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BackOrder Allowed',
        dataIndex: 'disti_backorder_allowed',
        width: 200,
        ...this.getColumnSearchProps('disti_backorder_allowed'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes(
          'disti_backorder_allowed'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed DF Date',
        dataIndex: 'manually_changed_df_date',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_df_date'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_df_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed DF Time',
        dataIndex: 'manually_changed_df_time',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_df_time'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_df_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed DF Username',
        dataIndex: 'manually_changed_df_username',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_df_username'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_df_username'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed DBO Date',
        dataIndex: 'manually_changed_dbo_date',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_dbo_date'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_dbo_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed DBO time',
        dataIndex: 'manually_changed_dbo_time',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_dbo_time'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_dbo_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed DBO Username',
        dataIndex: 'manually_changed_dbo_username',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_dbo_username'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_dbo_username'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed EC Date',
        dataIndex: 'manually_changed_ec_date',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_ec_date'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_ec_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed EC Time',
        dataIndex: 'manually_changed_ec_time',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_ec_time'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_ec_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed EC Username',
        dataIndex: 'manually_changed_ec_username',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_ec_username'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_ec_username'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'SOF',
        dataIndex: 'sof',
        width: 200,
        ...this.getColumnSearchProps('sof'),
        className: this.state.highlightFilterArray.includes('sof')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed CS Date',
        dataIndex: 'manually_changed_cs_date',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_cs_date'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_cs_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed CS Time',
        dataIndex: 'manually_changed_cs_time',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_cs_time'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_cs_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed CS Username',
        dataIndex: 'manually_changed_cs_username',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_cs_username'),
        className: this.state.highlightFilterArray.includes(
          'manually_changed_cs_username'
        )
          ? 'filtered_text'
          : '',
      },

      {
        title: 'Manually Created Date',
        dataIndex: 'manually_created_date',
        width: 280,
        ...this.getColumnSearchProps('manually_created_date'),
        className: this.state.highlightFilterArray.includes(
          'manually_created_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Created Time',
        dataIndex: 'manually_created_time',
        width: 280,
        ...this.getColumnSearchProps('manually_created_time'),
        className: this.state.highlightFilterArray.includes(
          'manually_created_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Created Username',
        dataIndex: 'manually_created_username',
        width: 280,
        ...this.getColumnSearchProps('manually_created_username'),
        className: this.state.highlightFilterArray.includes(
          'manually_created_username'
        )
          ? 'filtered_text'
          : '',
      },

      {
        title: 'Create Date',
        dataIndex: 'create_date',
        width: 280,
        ...this.getColumnSearchProps('create_date'),
        className: this.state.highlightFilterArray.includes('create_date')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Create Time',
        dataIndex: 'create_time',
        width: 280,
        ...this.getColumnSearchProps('create_time'),
        className: this.state.highlightFilterArray.includes('create_time')
          ? 'filtered_text'
          : '',
      },

      {
        title: 'Update Date',
        dataIndex: 'update_date',
        width: 280,
        ...this.getColumnSearchProps('update_date'),
        className: this.state.highlightFilterArray.includes('update_date')
          ? 'filtered_text'
          : '',
      },

      {
        title: 'Update Time',
        dataIndex: 'update_time',
        width: 280,
        ...this.getColumnSearchProps('update_time'),
        className: this.state.highlightFilterArray.includes('update_time')
          ? 'filtered_text'
          : '',
      },
    ]

    //Action button columns
    if (isPermission(PERMISSIONS_BY_NAME['Manage Catalog import'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Fragment>
              <Link className="ico_edit">
                <EditOutlined
                  onClick={() => this.setFormStatus(record, 'edit')}
                />
              </Link>
              {isPermission(PERMISSIONS_BY_NAME['Delete catalog']) && (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => this.handleDelete(record)}
                >
                  <Link to="" className="ico_delete">
                    <DeleteOutlined />
                  </Link>
                </Popconfirm>
              )}
            </Fragment>
          ) : null,
      })
    } else if (isPermission(PERMISSIONS_BY_NAME['View Catalog import'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Fragment>
              <Link className="ico_edit">
                <EyeOutlined
                  onClick={() => this.setFormStatus(record, 'edit')}
                />
              </Link>
            </Fragment>
          ) : null,
      })
    }
    //function to select records/rows of table
    const rowSelection = {
      selectedRowKeys: this.state.selectedCatalog,
      onChange: (selectedCatalogs, selectedRows) => {
        this.setState({ selectedCatalog: selectedCatalogs })
        
      },
    }
    const { dataSource, currentPage, count } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col

      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })

    

    const renderTable = () => {
      return (
        <div className="CatalogTableEditStyles">
          <div className="CatalogTableEdit_container">
            <div className="Table_top_row">
              <div className="Table_heading">Catalog management</div>
              <div className="Table_action_btns">
                <div className="table_search_block">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.fetchData('', this.state.pageLimit)}
                  >
                    Reset
                  </Button>
                </div>
                <Button
                  type="default"
                  size="large"
                  onClick={() => {
                    let selectedCatalogData = this.state.selectedCatalog
                    let data = []
                    this.state.dataSource.filter((i) => {
                      let mpnData = i.mpn
                      let index = selectedCatalogData.indexOf(mpnData)
                      if (index != -1) {
                        data.push(this.state.dataSource[index])
                      }
                    })

                    if (this.state.selectedCatalog.length > 0) {
                      let fileContent = []
                      for (let catalog of data) {
                        let csvObj = {}
                        for (let column of this.columns) {
                          if (column.dataIndex !== 'action') {
                            csvObj[column.dataIndex] = catalog[column.dataIndex]
                          }
                        }
                        fileContent.push(csvObj)
                      }
                      let headersToShow = this.columns
                        .map(
                          (column) =>
                            column.dataIndex !== 'action' && column.title
                        )
                        .filter((value) => value && value)
                      downloadExcel(
                        'catalog_import',
                        fileContent,
                        headersToShow
                      )
                    } else message.info('Select catalog to export')
                  }}
                >
                  Export
                </Button>
                <a
                  onClick={() => this.exportData()}
                  className="export_link"
                  download
                >
                  Export All
                </a>
                {isPermission(PERMISSIONS_BY_NAME['Delete catalogs']) && (
                  <Button size="large" onClick={() => this.handleDeleteCatalog()}>
                    Delete orders
                  </Button>
                )}
                {/* // )} */}
                {isPermission(PERMISSIONS_BY_NAME['Add Part']) && (
                  <Button
                    onClick={() => this.setFormStatus('', 'add')}
                    size="large"
                    type="primary"
                  >
                    Add Part
                  </Button>
                )}
              </div>
            </div>
            <div className="layout_content_box CatalogTableEdit_whitebox">
              <Table //render catalog table
                className="Table_block_main"
                components={components}
                scroll={{ x: 1500, y: 500 }}
                rowClassName={() => 'editable-row'}
                bordered
                rowSelection={rowSelection}
                dataSource={dataSource || []}
                columns={columns}
                pagination={{
                  onChange: this.onChangePageNumber,
                  current: currentPage,
                  total: count,
                  current: currentPage,
                  defaultPageSize: this.state.pageLimit,
                  showSizeChanger: true,
                  onShowSizeChange: this.onChangePageNumber,
                  showTotal: this.showTotal,
                }}
                loading={this.state.loader}
                rowKey="id"
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {this.state.isFormOpen ? (
          this.state.loader ? (
            <div className="loader">
              <Spin size="large" />{' '}
            </div>
          ) : (
            //Add/Edit Form
            <CatalogForm
              catalog={this.state.currentCatalog}
              currentPage={this.state.currentPage}
              onChangePageNumber={this.onChangePageNumber}
              setFormStatus={this.setFormStatus}
              isAddPart={this.state.isAddPart}
              pageLimit={this.state.pageLimit}
            />
          )
        ) : (
          renderTable()
        )}
      </div>
    )
  }
}

export default EditableTable
