import React, { Component } from 'react'
import { Layout, Form, Input, Button, Select, Spin, message } from 'antd'
import './EditProfile.scss'
import { NavLink } from 'react-router-dom'
import { HTTPService } from '../../otherServices/apiService'
import { ROLES } from '../../otherServices/constant'
import { routes } from '../../routes'
import { trimSpace, isPermission } from '../../otherServices/commonFunction'
import { PERMISSIONS_BY_NAME } from '../../otherServices/constant'
import { withRouter } from 'react-router-dom'
const { Content } = Layout

const { Option } = Select

class EditProfile extends Component {
  constructor(props) {
    super(props)
    let userDetail = JSON.parse(localStorage.getItem('userDetail'))
    this.state = {
      roles: props.roles,
      userDetail: { ...userDetail, role: ROLES[userDetail.role] },
      processLoader: false,
      historyRoute: ''
    }
  }

  componentDidMount() {
    let historyRoutePath = this.props.history && this.props.history.location.state
    HTTPService({
      method: 'GET',
      url: `user-role`,
    })
      .then((userRoles) => {
        this.setState({ roles: userRoles.data.results, historyRoute: historyRoutePath })
      })
      .catch((err) => message.error('Unable to fetch roles'))
  }


  //function to call API to submit data 
  onFinish = async (values) => {
    let { password, ...otherValues } = values
    let valuesData = trimSpace(otherValues);

    this.setState({ processLoader: true })
    const { confirm_password, ...restData } = valuesData
    try {
      let user = await HTTPService({
        method: 'PUT',
        url: `user/${this.state.userDetail.id}/`,
        data: { password, ...restData },
      })
      if (user.success) {
        localStorage.setItem('userDetail', JSON.stringify(user.data))
        this.setState({ processLoader: false })
        message.success('User profile updated')
      } else {
        this.setState({ processLoader: false })
        message.error(user.message)
      }
      this.props.history.push(this.state.historyRoute)
    } catch (ex) {
      
      this.setState({ processLoader: false })
      message.error('Error in updating profile')
    }
  }

  render() {
    const { roles = [], userDetail, processLoader } = this.state
    return (
      <div className="EditProfileStyles">
        {processLoader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}

        {roles.length > 0 ? (
          <Layout className="site-layout">
            <Content>
              <div className="layout_middel_content">
                <div className="EditProfile_container">
                  <div className="EditProfile__title">Edit Profile</div>
                  <div className="layout_content_box EditProfile_whitebox">
                    <Form
                      className="form_block_main EditProfile-form"
                      initialValues={{ ...userDetail }}
                      onFinish={this.onFinish}
                    >
                      <div className="formitem_row">
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your first name!',
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                          ]}
                        >
                          <Input placeholder="John" size="large" />
                        </Form.Item>
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your last name!',
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                          ]}
                        >
                          <Input placeholder="Doe" size="large" />
                        </Form.Item>
                      </div>
                      <div className="formitem_row">
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please input email!',
                            },
                            {
                              type: 'email',
                              message: 'Enter valid email',
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="JohnDoe@yopmail.com"
                            size="large"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Password"
                          name="password"
                        >
                          <Input.Password placeholder="********" size="large" />
                        </Form.Item>
                      </div>
                      <div className="formitem_row">
                        <Form.Item
                          label="Confirm Password"
                          name="confirm_password"
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  'The passwords does not match!'
                                )
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder="********" size="large" />
                        </Form.Item>
                        <Form.Item
                          label="Role"
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: 'Please select role!',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Role"
                            style={{ width: '100%' }}
                          >
                            {roles.map((role) => (
                              <Option value={role.id}>{role.role}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="formitem_action_row">
                        <Form.Item>
                          <NavLink to={this.state.historyRoute}>
                            <Button size="large">Back</Button>
                          </NavLink>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            size="large"
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        ) : (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
      </div>
    )
  }
}

export default withRouter(EditProfile)