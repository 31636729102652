import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import './CustomerChannel.scss'
import SimpleTable from '../../components/SimpleTable/SimpleTable'
import { withRouter } from 'react-router-dom'
const { Content } = Layout

class CustomerChannel extends Component {
  render() {
    return (
      <div className="CustomerChannelStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Customer Channel</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <SimpleTable />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default withRouter(CustomerChannel)
