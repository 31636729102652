import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import { withRouter } from 'react-router-dom'
import ProductFamilyTable from '../../components/ProductFamilyTable/ProductFamilyTable'
import './ProductFamily.scss'
const { Content } = Layout

class ProductFamily extends Component {
  render() {
    return (
      <div className="ProductFamilyStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Product Family</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <ProductFamilyTable />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default withRouter(ProductFamily)
