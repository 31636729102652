import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
class NoAccessPage extends Component {
  render() {
    return (
      <div><p> Seems You Do Not have View permission For Page.</p>
      </div>
    )
  }
}

export default NoAccessPage