import React, { Component } from 'react'
import { Input, Button, Form, Select, message, Spin } from 'antd'
import { HTTPService } from '../../otherServices/apiService'
import { ROLES } from '../../otherServices/constant'
import { trimSpace, isPermission, isDisabled } from '../../otherServices/commonFunction'
import { PERMISSIONS_BY_NAME } from '../../otherServices/constant'

const { Option } = Select

class EditUserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.user && { ...props.user, role: props.user.role },
      roles: props.roles,
      processLoader: false
    }
  }

  onFinish = async (values) => {
    this.setState({ processLoader: true })
    let valuesData = trimSpace(values);
    const { user } = this.state
    let newUpdatedUser
    try {
      if (!user) {
        newUpdatedUser = await HTTPService({
          method: 'POST',
          url: 'user/',
          data: { ...valuesData },
        })
        if (newUpdatedUser.success) {
          this.setState({ processLoader: false })
          message.success('User added')
          this.props.onChangePageNumber(1)
        } else {
          this.setState({ processLoader: false })
          message.error(newUpdatedUser.message)
        }
      } else {
        let { password, ...userData } = values
        newUpdatedUser = await HTTPService({
          method: 'PUT',
          url: `user/${user.id}/`,
          data: { ...userData },
        })
        if (newUpdatedUser) {
          this.setState({ processLoader: false })
          message.success('User updated')
          this.props.onChangePageNumber(this.props.currentPage)
        } else {
          this.setState({ processLoader: false })
          message.error(newUpdatedUser.message)
        }

      }
    } catch (ex) {
      this.setState({ processLoader: false })
      message.error("Submit Failed")
      if (ex.request.responseText) {
        let data = JSON.parse(ex.request.responseText).data
        data.email && message.error(data.email[0])
      }
    }

  }

  onFinishFailed = (errors) => {
    
    message.error("Enter the required fields!")
  }

  render() {
    const { user, roles = [], processLoader } = this.state
    const userRole = localStorage.getItem('role')
    
    return (
      <div className="EditUserFormStyles">
        {processLoader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}

        <div className="EditUserForm_container">
          <div className="EditUserForm__title">
            {user ? 'Edit ' : 'Add '} User
          </div>
          <div className="layout_content_box EditUserForm_whitebox">
            <Form
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              initialValues={{ ...user }}
              className="form_block_main edit-user-form"
            >
              <div className="formitem_row">
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input first name!',
                    },
                    {
                      whitespace: true,
                      message: "White space not allowed!"
                    }
                  ]}
                >
                  <Input placeholder="Enter First Name" size="large" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input last name!',
                    },
                    {
                      whitespace: true,
                      message: "White space not allowed!"
                    }
                  ]}
                >
                  <Input placeholder="Enter Password" size="large" />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Email / Username"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input email/username!',
                    },
                    {
                      type: 'email',
                      message: 'Enter valid email',
                    },
                    {
                      whitespace: true,
                      message: "White space not allowed!"
                    }
                  ]}
                >
                  <Input placeholder="Enter Email / Username" size="large" disabled={isDisabled(userRole) ? true : false}/>
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input password!',
                    }
                  ]}
                >
                  <Input
                    disabled={user ? true : false}
                    type="password"
                    placeholder="Enter Password"
                    size="large"
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select role!',
                    },
                  ]}
                  label="Role"
                >
                  <Select placeholder="Select Role" style={{ width: '100%' }}>
                    {roles.map((role) => (
                      <Option value={role.id}>{role.role}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item></Form.Item>
              </div>
              <div className="formitem_action_row">
                <Form.Item>
                  <Button size="large" onClick={this.props.setFormStatus}>
                    Back
                  </Button>
                  {isPermission(
                    PERMISSIONS_BY_NAME['Manage User Management']) && <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                  </Button>}
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default EditUserForm
