import React, { Component } from 'react'
import moment from 'moment'
import {
  Input,
  Button,
  Form,
  Select,
  message,
  Spin
} from 'antd'
import { HTTPService } from '../../otherServices/apiService'
import { PAGE_LIMIT, USER_SORTING, PERMISSIONS_BY_NAME } from '../../otherServices/constant'
import { trimSpace, isPermission } from '../../otherServices/commonFunction'
import './ManageDistributionTable.scss'
const { Option } = Select
const OPTIONS = [
  'Susie Rodgers',
  'Lizzie Salazar',
  'Jayden Greer',
  'Azah Anyehi',
  'Elston Gullan',
  'Amachea Jajah',
];

class DistributionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processLoader: false,
      distributionData: props.distributionData,
      users: [],
      isFormaData: false
    };
  }
  componentDidMount() {
    HTTPService({
      method: 'GET',
      url: `user/?ordering=-id&limit=${PAGE_LIMIT}&offset=0&${USER_SORTING}`,
    })
      .then((userRoles) => {
        this.setState({ users: userRoles.data.results, isFormaData: true })
      })
      .catch((err) => message.error('Unable to fetch roles'), this.setState({ isFormaData: false }))
  }
  handleChange = selectedItems => {
    this.setState({ selectedItems });

  }

  onFinish = async (values) => {
    this.setState({ processLoader: true })
    let distributionData = { ...values, id: this.props.distributionData && this.props.distributionData.id }
    let data = trimSpace(distributionData);

    if (!data.id) {
      let res;
      try {
        res = await HTTPService({
          method: 'POST',
          url: 'distribution-list/',
          data: { ...distributionData },
        })
        if (res.success) {
          message.success("Added Successfully")
          this.props.onChangePageNumber(1, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ distributionData: data })
        }

      } catch{
        message.error("Failed to Add Part")
        this.setState({ distributionData: data })
      }

    } else {
      let res;
      try {
        res = await HTTPService({
          method: 'PUT',
          url: `distribution-list/${distributionData.id}/`,
          data: { ...data },
        })
        if (res.success) {
          message.success("Updated Successfully")
          this.props.onChangePageNumber(this.props.currentPage, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ distributionData: data })
        }
      } catch{
        message.error("Failed to Update")
        this.setState({ distributionData: data })
      }
    }
    this.setState({ processLoader: false })
  }
  onFinishFailed = (errors) => {
    
    message.error("Enter the required fields!")
  }

  render() {
    const { selectedItems, users, distributionData } = this.state;
    const validationMessage = 'Maximum field value is '
    return (
      <div className="DistributionFormStyles">
        <div className="DistributionForm_container">
          Manage
          {this.state.isFormaData ? <div className="layout_content_box DistributionForm_whitebox">
            <Form
              className="form_block_main"
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              initialValues={{ ...distributionData }}
            >
              <div className="formitem_row">
                <Form.Item
                  label="Distribution Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input distribution name!',
                    },
                    {
                      max: 35,
                      message: `${validationMessage}35`,
                    },
                    {
                      whitespace: true,
                      message: 'Whitespace not allowed!',
                    },
                  ]}
                >
                  <Input placeholder="Distribution Name" size="large" />
                </Form.Item>
                <Form.Item
                  name="emails"
                  label="Select Users"
                  rules={[
                    {
                      required: true,
                      message: 'Please input select users name!',
                    },
                  ]}
                >
                  <Select 
                     className="multiple_select" 
                     mode="tags" 
                     placeholder="Select Users"
                     value={selectedItems} 
                     onChange={this.handleChange} 
                     style={{ width: '100%' }}
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                      option && option.children
                      ? option.children.indexOf(input.toLowerCase()) >= 0
                      : null
                    }
                    onSearch={this.onSearch}
                  >
                    
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_action_row">
                <Form.Item>
                  <Button
                    size="large"
                    onClick={() => this.props.setFormStatus('')}
                  >
                    Back
                  </Button>
                  {isPermission(PERMISSIONS_BY_NAME['Manage Distribution list']) && <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size="large"
                  >
                    Save
                  </Button>}
                </Form.Item>
              </div>
            </Form>
          </div> : <Spin className="loader1" />}
        </div>
      </div>
    )
  }
}

export default DistributionForm
