import React, { Component } from 'react'
import moment from 'moment'
import {
  Input,
  Button,
  Form,
  message,
  DatePicker,
  TimePicker,
  Select,
  Spin,
} from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons';
import { DATE_FORMAT, TIME_FORMAT, PERMISSIONS_BY_NAME } from '../../otherServices/constant'
import { HTTPService } from '../../otherServices/apiService'
import { trimSpace, isPermission, isDisabled } from '../../otherServices/commonFunction'
const { Option } = Select
class CatalogForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      catalog: props.catalog ? this.toMoment(props.catalog) : props.catalog,
      processLoader: false,
      isUser: true
    }
  }
  componentDidMount() {
    let role = localStorage.getItem('role')
    if (role == 'System Admin') {
      this.setState({ isUser: false })
    }
  }
  toMoment = (catalog) => {
    if (catalog.manually_changed_df_date) {
      catalog.manually_changed_df_date = moment(
        catalog.manually_changed_df_date,
        // DATE_FORMAT
      )
    }
    if (catalog.manually_changed_dbo_date) {
      catalog.manually_changed_dbo_date = moment(
        catalog.manually_changed_dbo_date,
        // DATE_FORMAT
      )
    }
    if (catalog.manually_changed_ec_date) {
      catalog.manually_changed_ec_date = moment(
        catalog.manually_changed_ec_date,
        // DATE_FORMAT
      )
    }
    if (catalog.manually_changed_df_time) {
      catalog.manually_changed_df_time = moment(
        catalog.manually_changed_df_time,
        TIME_FORMAT
      )
    }
    if (catalog.manually_changed_dbo_time) {
      catalog.manually_changed_dbo_time = moment(
        catalog.manually_changed_dbo_time,
        TIME_FORMAT
      )
    }
    if (catalog.manually_changed_ec_time) {
      catalog.manually_changed_ec_time = moment(
        catalog.manually_changed_ec_time,
        TIME_FORMAT
      )
    }

    if (catalog.manually_changed_cs_time) {
      catalog.manually_changed_cs_time = moment(
        catalog.manually_changed_cs_time,
        TIME_FORMAT
      )
    }
    if (catalog.manually_changed_cs_date) {
      catalog.manually_changed_cs_date = moment(
        catalog.manually_changed_cs_date,
      )
    }
    if (catalog.manually_created_date) {
      catalog.manually_created_date = moment(
        catalog.manually_created_date,

      )
    }
    if (catalog.manually_created_time) {
      catalog.manually_created_time = moment(
        catalog.manually_created_time,
        TIME_FORMAT
      )
    }

    if (catalog.create_date) {
      catalog.create_date = moment(
        catalog.create_date,

      )
    }
    if (catalog.create_time) {
      catalog.create_time = moment(
        catalog.create_time,
        TIME_FORMAT
      )
    }

    if (catalog.update_date) {
      catalog.update_date = moment(
        catalog.update_date,

      )
    }
    if (catalog.update_time) {
      catalog.update_time = moment(
        catalog.update_time,
        TIME_FORMAT
      )
    }
    return catalog
  }

  onFinish = async (values) => {
    this.setState({ processLoader: true })
    let catalog = { ...values, id: this.state.catalog && this.state.catalog.id }
    if (catalog.manually_changed_df_date) {
      catalog.manually_changed_df_date = moment(
        catalog.manually_changed_df_date
      ).format(DATE_FORMAT)
    }
    if (catalog.manually_changed_dbo_date) {
      catalog.manually_changed_dbo_date = moment(
        catalog.manually_changed_dbo_date
      ).format(DATE_FORMAT)
    }
    if (catalog.manually_changed_ec_date) {
      catalog.manually_changed_ec_date = moment(
        catalog.manually_changed_ec_date
      ).format(DATE_FORMAT)
    }
    if (catalog.manually_changed_df_time) {
      catalog.manually_changed_df_time = moment(
        catalog.manually_changed_df_time
      ).format(TIME_FORMAT)
    }
    if (catalog.manually_changed_dbo_time) {
      catalog.manually_changed_dbo_time = moment(
        catalog.manually_changed_dbo_time
      ).format(TIME_FORMAT)
    }
    if (catalog.manually_changed_ec_time) {
      catalog.manually_changed_ec_time = moment(
        catalog.manually_changed_ec_time
      ).format(TIME_FORMAT)
    }
    if (catalog.manually_changed_cs_time) {
      catalog.manually_changed_cs_time = moment(
        catalog.manually_changed_cs_time
      ).format(TIME_FORMAT)
    }
    if (catalog.manually_changed_cs_date) {
      catalog.manually_changed_cs_date = moment(
        catalog.manually_changed_cs_date
      ).format(DATE_FORMAT)
    }

    if (catalog.manually_created_date) {
      catalog.manually_created_date = moment(
        catalog.manually_created_date
      ).format(DATE_FORMAT)
    }
    if (catalog.manually_created_time) {
      catalog.manually_created_time = moment(
        catalog.manually_created_time
      ).format(TIME_FORMAT)
    }

    if (catalog.create_date) {
      catalog.create_date = moment(
        catalog.create_date
      ).format(DATE_FORMAT)
    }
    if (catalog.create_time) {
      catalog.create_time = moment(
        catalog.create_time
      ).format(TIME_FORMAT)
    }
    if (catalog.update_date) {
      catalog.update_date = moment(
        catalog.update_date
      ).format(DATE_FORMAT)
    }
    if (catalog.update_time) {
      catalog.update_time = moment(
        catalog.update_time
      ).format(TIME_FORMAT)
    }

    let catalogData = trimSpace(catalog);

    if (!catalog.id) {
      let res;
      try {
        res = await HTTPService({
          method: 'POST',
          url: 'catalog/',
          data: { ...catalogData },
        })
        if (res.success) {
          message.success("Added Successfully")
          this.props.onChangePageNumber(1, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ catalog: catalogData })
        }

      } catch{
        message.error("Failed to Add Part")
        this.setState({ catalog: catalogData })
      }

    } else {
      let res;
      try {
        res = await HTTPService({
          method: 'PUT',
          url: `catalog/${catalog.id}/`,
          data: { ...catalog },
        })
        if (res.success) {
          message.success("Updated Successfully")
          this.props.onChangePageNumber(this.props.currentPage, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ catalog: catalogData })
        }

      } catch{
        message.error("Failed to Update")
        this.setState({ catalog: catalogData })
      }
    }
    this.setState({ processLoader: false })
  }

  onFinishFailed = (errors) => {
    message.error("Enter the required fields!")
  }

  render() {
    const { catalog = {}, processLoader, isUser } = this.state;
    const { isAddPart } = this.props
    const userRole = localStorage.getItem('role')
    const validationMessage = "Maximum field value is "
    return (
      <div className="AddCatalogStyles">
        {processLoader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}

        <div className="AddCatalog_container">
          <div className="AddCatalog__title">
            {catalog ? 'Edit ' : 'Add '} Catalog
          </div>
          <div className="layout_content_box AddCatalog_whitebox">
            <Form
              className="form_block_main add-catalog-form"
              initialValues={{ ...catalog }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              scrollToFirstError
            >
              <div className="formitem_row">
                <Form.Item
                  label="MPN"
                  name="mpn"
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please Enter MPN!',
                    },
                    {
                      max: 40,
                      message: `${validationMessage}40`
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ] : [{
                    max: 40,
                    message: `${validationMessage}40`
                  }]}
                >
                  <Input placeholder="Enter MPN" size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false} />
                </Form.Item>
                <Form.Item
                  label="Company Of Origin"
                  name="company_of_origin"
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please Enter Company Of Origin!',
                    },
                    {
                      max: 6,
                      message: `${validationMessage}6`
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ] : [{
                    max: 6,
                    message: `${validationMessage}6`
                  }]}
                >
                  <Input
                    maxLength={6}
                    placeholder="Enter Company Of Origin"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Business Unit"
                  name="business_unit">
                  <Input placeholder="Enter Business Unit" size="large" />
                </Form.Item>
                <Form.Item
                  label="Division"
                  name="division"
                  rules={[{
                    max: 10,
                    message: `${validationMessage}10`
                  }]}
                >
                  <Input
                    maxLength={10}
                    placeholder="Enter Division"
                    size="large"
                    disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Product Family"
                  name="prod_family"
                  rules={[{
                    max: 35,
                    message: `${validationMessage}35`
                  }]}
                >
                  <Input placeholder="Enter Prod Family" size="large"
                    disabled={isUser ? !isDisabled(userRole) && !isAddPart : false} />
                </Form.Item>
                <Form.Item
                  label="Desc"
                  name="desc"
                  rules={[{
                    max: 132,
                    message: `${validationMessage}132`
                  }]}
                >
                  <Input placeholder="Enter Desc" size="large"
                    disabled={isUser ? !isDisabled(userRole) && !isAddPart : false} />
                </Form.Item>
              </div>
              <div className="formitem_row">
                {isAddPart ? <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Can Sample',
                    }
                  ]}
                  label="Can Sample"
                  name="can_sample"
                >
                  <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="Can Sample" style={{ width: '100%' }}>
                    <Option value="1">Yes</Option>
                    <Option value="0">No</Option>
                  </Select>
                </Form.Item> :
                  <Form.Item
                    label="Can Sample"
                    name="can_sample"
                  >
                    <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="Can Sample" style={{ width: '100%' }}
                      disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>}
                <Form.Item
                  label="Market Family"
                  name="market_family"
                  rules={[{
                    max: 45,
                    message: `${validationMessage}45`
                  }]}
                >
                  <Input placeholder="Enter Market Family" size="large"
                    disabled={isUser ? !isDisabled(userRole) && !isAddPart : false} />
                </Form.Item>
              </div>
              <div className="formitem_row">
              
                  <Form.Item
                    label="Itar"
                    name="itar"
                  >
                    <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="Itar" style={{ width: '100%' }}
                      disabled={isUser ? isDisabled(userRole) && !isAddPart : false}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>

                <Form.Item
                  label="ECCN"
                  name="eccn"
                  rules={ [{
                    max: 20,
                    message: `${validationMessage}20`
                  }]}
                >
                  <Input maxLength={20} placeholder="Enter ECCN" size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false} />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please Enter Life Cycle!',
                    },
                    {
                      max: 20,
                      message: `${validationMessage}20`
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ] : [{
                    max: 20,
                    message: `${validationMessage}20`
                  }]}
                  label="Life Cycle"
                  name="life_cycle"
                >
                  <Input
                    maxLength={20}
                    placeholder="Enter Life Cycle"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
                {isAddPart ? <Form.Item
                  label="Digikey Fulfill Allowed"
                  name="disti_fulfill_allowed"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Digikey Fulfill Allowed!',
                    }
                  ]}
                >
                  <Select
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    placeholder="Digikey Fulfill Allowed"
                    style={{ width: '100%' }}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item> :
                  <Form.Item
                    label="Digikey Fulfill Allowed"
                    name="disti_fulfill_allowed">
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      placeholder="Digikey Fulfill Allowed"
                      style={{ width: '100%' }}
                      disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </Form.Item>}
              </div>
              <div className="formitem_row">
               
                  <Form.Item
                    label="Export Controlled"
                    name="export_controlled">
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      placeholder="Export Controlled"
                      style={{ width: '100%' }}
                      disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </Form.Item>
                
                
                  <Form.Item
                    label="BackOrder Allowed"
                    name="disti_backorder_allowed">
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      placeholder="Backorder Allowed"
                      style={{ width: '100%' }}
                      disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="manually_changed_df_date"
                  label="Manually Changed DF Date"
                  
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_df_date &&
                      moment(catalog.manually_changed_df_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  name="manually_changed_df_time"
                  label="Manually Changed DF Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_df_time &&
                      moment(catalog.manually_changed_df_time, 'TIME_FORMAT')
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Manually Changed DF Username"
                  name="manually_changed_df_username"
                  rules={ [{
                    max: 50,
                    message: `${validationMessage}50`
                  }]}
                >
                  <Input
                    placeholder="Enter Manually Changed DF Username"
                    size="large"

                  />
                </Form.Item>

                <Form.Item
                  name="manually_changed_dbo_date"
                  label="Manually Changed DBO Date"
                  
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_dbo_date &&
                      moment(catalog.manually_changed_dbo_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
              </div>
              <div className="formitem_row">

                <Form.Item
                  name="manually_changed_dbo_time"
                  label="Manually Changed DBO Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_dbo_time &&
                      moment(catalog.manually_changed_dbo_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  label="Manually Changed DBO Username"
                  name="manually_changed_dbo_username"
                  rules={[{
                    max: 50,
                    message: `${validationMessage}50`
                  }]}
                >
                  <Input
                    placeholder="Enter Manually Changed DBO Username"
                    size="large"

                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="manually_changed_ec_date"
                  label="Manually Changed EC Date"
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_ec_date &&
                      moment(catalog.manually_changed_ec_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  name="manually_changed_ec_time"
                  label="Manually Changed EC Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_ec_time &&
                      moment(catalog.manually_changed_ec_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
              </div>
              <div className="formitem_row">

                <Form.Item
                  label="Manually Changed EC Username"
                  name="manually_changed_ec_username"
                  rules={[{
                    max: 50,
                    message: `${validationMessage}50`
                  }]}

                  >
                  
                  <Input
                    placeholder="Enter Manually Changed EC Username"
                    size="large"

                  />
                </Form.Item>
                {isAddPart ? <Form.Item
                  label="SOF"
                  name="sof"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter SOF!',
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ]}
                >
                  <Input placeholder="Enter sof" size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false} />
                </Form.Item> :
                  <Form.Item
                    label="SOF"
                    name="sof">
                    <Input placeholder="Enter sof" size="large"
                      disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                    />
                  </Form.Item>}
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="manually_changed_cs_date"
                  label="Manually Changed CS Date"
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_cs_date &&
                      moment(catalog.manually_changed_cs_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  name="manually_changed_cs_time"
                  label="Manually Changed CS Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_changed_cs_time &&
                      moment(catalog.manually_changed_cs_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
              </div>

              <div className="formitem_row">

                <Form.Item
                  label="Manually Changed CS Username"
                  name="manually_changed_cs_username"
                  rules={[{
                    max: 50,
                    message: `${validationMessage}50`
                  }]}

                  >
                  <Input
                    placeholder="Enter Manually Changed CS Username"
                    size="large"

                  />
                </Form.Item>

                <Form.Item
                  name="manually_created_date"
                  label="Manually Created Date"
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_created_date &&
                      moment(catalog.manually_created_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="manually_created_time"
                  label="Manually Created Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.manually_created_time &&
                      moment(catalog.manually_created_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>

                <Form.Item
                  label="Manually Created Username"
                  name="manually_created_username"
                  rules={[{
                    max: 50,
                    message: `${validationMessage}50`
                  }]}
                  >
                  <Input
                    placeholder="Enter Manually Created Username"
                    size="large"

                  />
                </Form.Item>
              </div>




              <div className="formitem_row">
                <Form.Item
                  name="create_date"
                  label="Create Date"
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.create_date &&
                      moment(catalog.create_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  name="create_time"
                  label="Create Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.create_time &&
                      moment(catalog.create_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="update_date"
                  label="Update Date"
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.update_date &&
                      moment(catalog.update_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  name="update_time"
                  label="Update Time"
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                      catalog &&
                      catalog.update_time &&
                      moment(catalog.update_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
              </div>




              <div className="formitem_action_row" id="bottom">
                <Form.Item>
                  <Button size="large" onClick={() => this.props.setFormStatus('', '')}>
                    Back
                  </Button>
                  {isPermission(
                    PERMISSIONS_BY_NAME['Manage Catalog import']) && <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                  </Button>}
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="fixed_scroll_button">
            <Button type="primary" className="fixed_button"
              size="large" onClick={() => {
                var elmnt = document.getElementById("bottom");

                elmnt.scrollIntoView();
              }}> Scroll to bottom<ArrowDownOutlined /></Button>
          </div>
        </div>
      </div>
    )
  }
}

export default CatalogForm
