import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import { withRouter } from 'react-router-dom'
import './BusinessRules.scss'
import BusinessRuleTable from '../../components/BusinessRulesTable/BusinessRulesTable'
const { Content } = Layout

class BusinessRules extends Component {
  render() {
    return (
      <div className="ManageDistributionStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Business Rule</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
            <BusinessRuleTable />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default withRouter(BusinessRules)
