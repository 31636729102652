import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Select,
  message,
  Spin,
  Space,
} from 'antd'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import './TableEdit.scss'
import EditUserForm from './EditUserForm'
import { HTTPService } from '../../otherServices/apiService'
import {
  PAGE_LIMIT,
  USER_SORTING,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import {
  exportCSVFile,
  downloadExcel,
  isPermission,
} from '../../otherServices/commonFunction'

const EditableContext = React.createContext()
const { Option } = Select
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    
  },
  onSelect: (record, selected, selectedRows) => {
      },
  onSelectAll: (selected, selectedRows, changeRows) => {
    
  },
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async (e) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      
    }
  }

  let childNode = children

  if (dataIndex !== 'password' || record.isNewuser) {
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 20,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>
}

class EditableTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div className="search_filterDropdown">
        <Input
          className="search_input"
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            clearFilters()
            this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
          }}
        />
        <Space className="btns">
          <Button
            type="primary"
            onClick={() => {
              clearFilters()
              this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
            }}
            icon={<SearchOutlined />}
            size="medium"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              this.onFilterReset(`${dataIndex}`)
            }}
            size="medium"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#3653AA' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#fafafa', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }
  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '', filterValueArray: [] })
  }

  constructor(props) {
    super(props)

    this.state = {
      loader: false,
      apiLoaded: false,
      dataSource: null,
      pageSize: PAGE_LIMIT,
      count: 2,
      offset: 0,
      currentPage: 1,
      currentUser: null,
      roles: [],
      filterValueArray: [],
      isFilter: false,
      filterString: '',
      selectedUser: [],
      searchText: null,
      selectAllExportData: [],
      exportURL: '',
      highlightFilterArray: [],
      // highlightFilterArray: [],
    }
  }
  componentDidMount() {
    //function to fetcg user data and roles
    
    if (this.state.isFilter) {
      this.getFilteredData(this.state.filterString)
    } else {
      this.fetchData()
    }
  }

  fetchData = async (searchValue = '') => {
    this.setState({ highlightFilterArray: [], loader: true })
    await Promise.all([
      HTTPService({
        method: 'GET',
        url: `user/?ordering=-id&limit=${
          this.state.pageSize
        }&offset=0&${USER_SORTING}${searchValue && searchValue}`,
      }),
      HTTPService({
        method: 'GET',
        url: `user-role/`,
      }),
    ])
      .then(([userRes, userRole]) => {
        let users = [],
          count = 0
        for (let user of userRes.data.results) {
          users.push({ ...user, password: '********' })
        }
        count = userRes.data.count
        this.setState({
          dataSource: users,
          count,
          isFilter: false,
          apiLoaded: true,
          roles: userRole.data.results,
          loader: false,
          searchText: searchValue || null,
        })
      })
      .catch((ex) => {
        this.setState({
          dataSource: [],
          apiLoaded: true,
          roles: [],
        })
      })
  }

  async getFilteredData(searchValue = '') {
    this.setState({ loader: true })
    await Promise.all([
      HTTPService({
        method: 'GET',
        url: `user/?ordering=-id&limit=${this.state.pageSize}&offset=0&${searchValue}`,
      }),
      HTTPService({
        method: 'GET',
        url: `user-role/`,
      }),
    ])
      .then(([userRes, userRole]) => {
        let users = [],
          count = 0
        for (let user of userRes.data.results) {
          users.push({ ...user, password: '********' })
        }
        count = userRes.data.count
        this.setState({
          dataSource: users,
          count,
          apiLoaded: true,
          roles: userRole.data.results,
          searchText: searchValue || null,
        })
      })
      .catch((ex) => {
        this.setState({
          dataSource: [],
          apiLoaded: true,
          roles: [],
        })
      })
    this.setState({ loader: false })
  }

  //function to delete records
  handleDelete = async (user) => {
    if (!user.isNewuser) {
      try {
        await HTTPService({ url: `user/${user.id}/`, method: 'DELETE' })
        message.success('User deleted')
      } catch (ex) {
        message.error('Error occured')
        return
      }
    }

    let { count, currentPage } = this.state
    let lastPage = Math.ceil(count / PAGE_LIMIT)
    let totalRecordBeforeLastPage = (lastPage - 1) * PAGE_LIMIT
    let lastPageRecord = count - totalRecordBeforeLastPage

    if (currentPage === 1) {
      await this.onChangePageNumber(currentPage)
    } else {
      if (lastPageRecord === 1) this.onChangePageNumber(currentPage - 1)
      else this.onChangePageNumber(currentPage)
    }
  }

  //function to toggle flag for Add/Edit form
  setFormStatus = (user) => {
    this.setState({
      isFormOpen: !this.state.isFormOpen,
      currentUser: user ? user : null,
    })
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData,
    })
  }

  //function fetch data on page change
  onChangePageNumber = async (pageNumber, pageSize) => {
    this.setState({ loader: true, pageNumber, pageSize, selectedUser: [] })
    let offset = (pageNumber - 1) * pageSize
    let userRes
    if (this.state.isFilter) {
      let searchText = this.state.searchText
      userRes = await HTTPService({
        method: 'GET',
        url: `user/?limit=${pageSize}&offset=${offset}&${USER_SORTING}&${searchText}`,
      })
    }
    userRes = await HTTPService({
      method: 'GET',
      url: `user/?limit=${pageSize}&offset=${offset}&${USER_SORTING}`,
    })

    let users = [],
      count = 0
    for (let user of userRes.data.results) {
      users.push({ ...user, password: '********' })
    }
    count = userRes.data.count
    this.setState({
      dataSource: users,
      count,
      currentPage: pageNumber,
      isFormOpen: false,
      currentUser: null,
      loader: false,
    })
  }
  // handleSizeChange = async (pageNumber, pageSize) => {
  
  //   this.setState({
  //     loader: true,
  //     pageNumber,
  //     pageSize,
  //   })
  //   let offset = (pageNumber - 1) * pageSize
  //   let userRes = await HTTPService({
  //     method: 'GET',
  //     url: `user/?limit=${pageSize}&offset=${offset}&${USER_SORTING}`,
  //   })
  //   this.setState({ loader: false })
  // }
  showTotal = () => {
    return `Total ${this.state.count}`
  }
  //columns
  onSearch = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray

    arrayOfFilter.filter((i, index) => {
      let stringSplit = value.split('=')
      if (i.includes(stringSplit[0])) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    arrayOfFilter.push(value)
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      filterString: `${arrayOfFilter.join('&&')}`,
      highlightFilterArray: highlightArray,
    })
    this.getFilteredData(`${arrayOfFilter.join('&&')}`)
  }

  onFilterReset = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray

    arrayOfFilter.filter((i, index) => {
      if (i.includes(value)) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      highlightFilterArray: highlightArray,
    })
    if (arrayOfFilter.length == 0) {
      this.fetchData('')
    } else {
      this.setState({ filterString: `${arrayOfFilter.join('&&')}` })
      this.getFilteredData(`${arrayOfFilter.join('&&')}`)
    }
  }
  exportData = () => {
    if (this.state.isFilter) {
      this.getExportDataFiltered()
    } else {
      this.getExportData()
    }
  }
  async getExportDataFiltered() {
    let urlLink = `export/user-xls/?${this.state.searchText}`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      
    }
  }

  async getExportData() {
    let urlLink = `export/user-xls`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      
    }
  }

  // onPageSizeChange = async(pageNumber, pageSize)

  render() {
    this.columns = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        width: '20%',
        ...this.getColumnSearchProps('first_name'),
        // className: 'filtered_text',
        className: this.state.highlightFilterArray.includes('first_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        width: '20%',
        ...this.getColumnSearchProps('last_name'),
        className: this.state.highlightFilterArray.includes('last_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Email / Username',
        dataIndex: 'email',
        width: '35%',
        ...this.getColumnSearchProps('email'),
        className: this.state.highlightFilterArray.includes('email')
          ? 'filtered_text'
          : '',
        render: (text, record) => (
          <Fragment>
            <span className="emailid">{text}</span>
          </Fragment>
        ),
      },
      {
        title: 'Password',
        dataIndex: 'password',
        width: '15%',
        ...this.getColumnSearchProps('password'),
        className: this.state.highlightFilterArray.includes('password')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '20%',
        ...this.getColumnSearchProps('role'),
        className: this.state.highlightFilterArray.includes('role')
          ? 'filtered_text'
          : '',
      },
    ]

    //action button column
    if (isPermission(PERMISSIONS_BY_NAME['Manage User Management'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: 80,
        align: 'center',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Fragment>
              <Link className="ico_edit">
                <EditOutlined onClick={() => this.setFormStatus(record)} />
              </Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record)}
              >
                <Link className="ico_delete">
                  <DeleteOutlined />
                </Link>
              </Popconfirm>
            </Fragment>
          ) : null,
      })
    } else if (isPermission(PERMISSIONS_BY_NAME['View User Management'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: 80,
        align: 'center',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Fragment>
              <Link className="ico_edit">
                <EyeOutlined onClick={() => this.setFormStatus(record)} />
              </Link>
            </Fragment>
          ) : null,
      })
    }

    const {
      dataSource,
      count,
      currentPage,
      apiLoaded,
      currentUser,
      roles,
    } = this.state
    if (apiLoaded) {
      const rowSelection = {
        selectedRowKeys: this.state.selectedUser,
        onChange: (selectedUsers, selectedRows) => {
          this.setState({ selectedUser: selectedUsers })
        },
      }
      const { dataSource, currentPage, count } = this.state
      const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }
      const columns = this.columns.map((col) => {
        if (!col.editable) {
          return col
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        }
      })
      const renderTable = () => {
        return (
          <div className="TableEditStyles">
            <div className="TableEdit_container">
              <div className="Table_top_row">
                <div className="Table_heading">User management</div>
                {isPermission(
                  PERMISSIONS_BY_NAME['Manage User Management']
                ) && (
                  <div className="Table_action_btns">
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => this.fetchData('', this.state.pageLimit)}
                    >
                      Reset
                    </Button>
                    <Button
                      type="default"
                      size="large"
                      onClick={() => {
                        let selectedUserData = this.state.selectedUser
                        let data = []
                        this.state.dataSource.filter((i) => {
                          let emailData = i.email
                          let index = selectedUserData.indexOf(emailData)
                          if (index != -1) {
                            data.push(this.state.dataSource[index])
                          }
                        })

                        if (this.state.selectedUser.length > 0) {
                          let fileContent = []
                          for (let catalog of data) {
                            let csvObj = {}
                            for (let column of this.columns) {
                              if (column.dataIndex !== 'action') {
                                csvObj[column.dataIndex] =
                                  catalog[column.dataIndex]
                              }
                            }
                            fileContent.push(csvObj)
                          }
                          let headersToShow = this.columns
                            .map(
                              (column) =>
                                column.dataIndex !== 'action' && column.title
                            )
                            .filter((value) => value && value)
                          downloadExcel(
                            'user_import',
                            fileContent,
                            headersToShow
                          )
                        } else message.info('Select User to export')
                      }}
                    >
                      Export
                    </Button>
                    <a
                      onClick={() => this.exportData()}
                      className="export_link"
                      download
                    >
                      Export All
                    </a>
                    <Button
                      onClick={() => this.setFormStatus()}
                      size="large"
                      type="primary"
                    >
                      Add User
                    </Button>
                  </div>
                )}
              </div>
              <div className="layout_content_box CatalogTableEdit_whitebox">
                <Table //user table
                  className="Table_block_main"
                  rowClassName={() => 'editable-row'}
                  components={components}
                  scroll={{ y: 500 }}
                  bordered
                  rowSelection={rowSelection}
                  dataSource={dataSource || []}
                  columns={columns}
                  pagination={{
                    onChange: this.onChangePageNumber,
                    current: currentPage,
                    total: count,
                    defaultPageSize: this.state.pageSize,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    onShowSizeChange: this.onChangePageNumber,
                    showSizeChanger: true,
                    showTotal: this.showTotal,
                  }}
                  loading={this.state.loader}
                  rowKey="email"
                />
              </div>
            </div>
          </div>
        )
      }

      return (
        <div>
          {this.state.isFormOpen ? (
            <EditUserForm //Add/Edit form
              roles={roles}
              user={currentUser}
              onChangePageNumber={this.onChangePageNumber}
              currentPage={currentPage}
              setFormStatus={this.setFormStatus}
            />
          ) : (
            renderTable()
          )}
        </div>
      )
    } else {
      return (
        <div className="loader">
          <Spin size="large" />
        </div>
      )
    }
  }
}

export default EditableTable
