import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { Dropdown } from 'antd'
import {
  UsergroupAddOutlined,
  ProfileOutlined,
  UserOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  TrademarkOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PartitionOutlined,
  MonitorOutlined,
  ShopOutlined,
  FileTextOutlined,
  CloudUploadOutlined,
  SnippetsOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { images } from '../config/images'
import { clearStorage, isPermission } from '../otherServices/commonFunction'
import { routes } from '../routes'
import { PERMISSIONS_BY_NAME } from '../otherServices/constant'
const { Header, Content, Footer, Sider } = Layout

const profile = (props, { firstName = '', lastName = '', role = '' }) => {
  return (
    <Menu className="profile_dropdown_main">
      <Menu.Item className="profile_list_block">
        <div className="profile_list_row">
          <div className="profile_user_details">
            <div className="profile_username">{firstName + ' ' + lastName}</div>
            <div className="profile_user_position">{role}</div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item className="profile_list_block">
        <Link
          to={{
            pathname: `/editprofile`,
            state: window.location.pathname,
          }}
        >
          <UserOutlined />
          <span>Edit profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item className="profile_list_block">
        <span
          onClick={() => {
            clearStorage()
            props.history.push(routes.login.path)
          }}
        >
          <UploadOutlined />
          <span>Sign out</span>
        </span>
      </Menu.Item>
    </Menu>
  )
}

class LayoutMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      firstName: '',
      lastName: '',
      role: '',
    }
  }

  componentDidMount() {
    let userDetail =
      (localStorage.getItem('userDetail') &&
        JSON.parse(localStorage.getItem('userDetail'))) ||
      {}
    this.setState({
      firstName: userDetail.first_name,
      lastName: userDetail.last_name,
      role: userDetail.role,
    })
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed })
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    const { firstName, lastName, role } = this.state
    if (
      this.props.history.location.pathname === '/login' ||
      this.props.history.location.pathname === '/forgotpassword'
    ) {
      return (
        <Content>
          <div className="layout_middel_container">{this.props.children}</div>
        </Content>
      )
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          width={230}
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
        >
          <div className="Sidebar_Header_Logo">
            <img src={images.Logo} alt="logo" />
          </div>
          <Menu
            defaultSelectedKeys={this.props.location.pathname}
            mode="inline"
          >
            {isPermission(PERMISSIONS_BY_NAME['View User Management']) && (
              <Menu.Item key={routes.usermanagement.path}>
                <UsergroupAddOutlined />
                <span>User Management</span>
                <Link to="/usermanagement" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View Catalog import']) && (
              <Menu.Item key={routes.catalogmanagement.path}>
                <ProfileOutlined />
                <span>Catalog Management</span>
                <Link to="/catalogmanagement" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View Sample Order']) && (
              <Menu.Item key={routes.sampleorder.path}>
                <UnorderedListOutlined />
                <span>Sample Order</span>
                <Link to="/sampleorder" />
              </Menu.Item>
            )}
            {isPermission(
              PERMISSIONS_BY_NAME['View User Role permissions']
            ) && (
              <Menu.Item key={routes.userroles.path}>
                <UserOutlined />
                <span>User Roles</span>
                <Link to="/userroles" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View Business Rules']) && (
              <Menu.Item key={routes.businessrules.path}>
                <TrademarkOutlined />
                <span>Business Rules</span>
                <Link to={routes.businessrules.path} />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View Distribution list']) && (
              <Menu.Item key={routes.managedistribution.path}>
                <PartitionOutlined />
                <span>Manage distribution</span>
                <Link to="/managedistribution" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View FTP settings']) && (
              <Menu.Item key={routes.ftpsettings.path}>
                <SettingOutlined />
                <span>FTP Settings</span>
                <Link to="/ftpsettings" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View FTP settings']) && (
              <Menu.Item key={routes.sftpsettings.path}>
                <SettingOutlined />
                <span>SFTP Settings</span>
                <Link to="/sftpsettings" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View Customer channel']) && (
              <Menu.Item key={routes.customerchannel.path}>
                <CustomerServiceOutlined />
                <span>Customer Channel</span>
                <Link to="/customerchannel" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['View DPL']) && (
              <Menu.Item key={routes.dplScreening.path}>
                <MonitorOutlined />
                <span>DPL Screening Logs</span>
                <Link to="/dplScreening" />
              </Menu.Item>
            )}
            <Menu.Item key={routes.dklInventory.path}>
              <ShopOutlined />
              <span>DK Inventory availability</span>
              <Link to="/dkinventory" />
            </Menu.Item>
            {isPermission(PERMISSIONS_BY_NAME['View DK Inventory Report']) && (
              <Menu.Item key={routes.dkInventoryReport.path}>
                <FileTextOutlined />
                <span>DK Inventory Report</span>
                <Link to="/dkreport" />
              </Menu.Item>
            )}
            {isPermission(PERMISSIONS_BY_NAME['EUS Hold Release']) && (
              <Menu.Item key={routes.eusholdrelease.path}>
                <CloudUploadOutlined />
                <span>EUS Hold Mass Release</span>
                <Link to="/eusholdrelease" />
              </Menu.Item>
            )}
            <Menu.Item key={routes.productFamily.path}>
              <SnippetsOutlined />
              <span>Product Family</span>
              <Link to="/productfamily" />
            </Menu.Item>
            <Menu.Item key={routes.erpordernumber.path}>
              <CloudUploadOutlined />
              <span>Update ERP Order Number</span>
              <Link to="/erpordernumber" />
            </Menu.Item>
            <Menu.Item key={routes.sampleOrderAudit.path}>
              <SnippetsOutlined />
              <span>Sample Order Audit</span>
              <Link to="/sampleOrderAudit" />
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site_header">
            <div className="Header_left_column">
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: this.toggle,
                }
              )}
            </div>
            <div className="Header_right_column">
              <div className="Header_user_avatar">
                <Dropdown overlay={profile(this.props, this.state)}>
                  <Link
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="Header_username">
                      {firstName + ' ' + lastName}
                    </span>
                  </Link>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content>
            <div className="layout_middel_container">{this.props.children}</div>
          </Content>
          <Footer style={{ position: 'fixed' }}>RORE ©2020</Footer>
        </Layout>
      </Layout>
    )
  }
}

export const AppLayout = withRouter(LayoutMain)
