import React, { Component } from 'react'
import moment from 'moment'
import {
  Input,
  Button,
  Form,
  message,
  DatePicker,
  TimePicker,
  Select,
  Spin,
} from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons';
import { DATE_FORMAT, TIME_FORMAT, PERMISSIONS_BY_NAME } from '../../otherServices/constant'
import { HTTPService } from '../../otherServices/apiService'
import { trimSpace, isPermission, isDisabled, testAscii, exportCSVFile,
    downloadExcel,
   } from '../../otherServices/commonFunction'
const { Option } = Select

let isCoreInvQty
let isConsignInvQtyValidated

class DkInventoryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      report: props.report ? this.toMoment(props.report) : props.report,
      processLoader: false,
      isUser: true
    }
  }
  componentDidMount() {
    let role = localStorage.getItem('role')
    if (role == 'System Admin') {
      this.setState({ isUser: false })
    }
  }
  toMoment = (report) => {
    if (report.load_time) {
        report.load_time = moment(
          report.load_time,
          TIME_FORMAT
        )
      }
      if (report.load_date) {
        report.load_date = moment(
          report.load_date,
        )
      }
    return report
  }

  onFinish = async (values) => {
    this.setState({ processLoader: true })
    let report = { ...values, id: this.state.report && this.state.report.id }
    
    if (report.load_time) {
        report.load_time = moment(
          report.load_time
        ).format(TIME_FORMAT)
      }
      if (report.load_date) {
        report.load_date = moment(
            report.load_date
        ).format(DATE_FORMAT)
      }

    let reportData = trimSpace(report);

    if (!report.id) {
      let res;
      try {
        res = await HTTPService({
          method: 'POST',
          url: 'dk-inv-report/',
          data: { ...reportData },
        })
        if (res.success) {
          message.success("Added Successfully")
          this.props.onChangePageNumber(1, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ report: reportData })
        }

      } catch{
        message.error("Failed to Add Part")
        this.setState({ report: reportData })
      }

    } else {
      let res;
      try {
        res = await HTTPService({
          method: 'PUT',
          url: `dk-inv-report/${report.id}/`,
          data: { ...report },
        })
        if (res.success) {
          message.success("Updated Successfully")
          this.props.onChangePageNumber(this.props.currentPage, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ report: reportData })
        }

      } catch{
        message.error("Failed to Update")
        this.setState({ report: reportData })
      }
    }
    this.setState({ processLoader: false })
  }

  onFinishFailed = (errors) => {
    message.error("Enter the required fields!")
  }

  render() {
    const { report= {}, processLoader, isUser } = this.state;
    const { isAddPart } = this.props
    const userRole = localStorage.getItem('role')
    const validationMessage = "Maximum field value is "
    return (
      <div className="AddCatalogStyles">
        {processLoader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}

        <div className="AddCatalog_container">
          <div className="AddCatalog__title">
            {report? 'Edit' : 'Add' } Report
          </div>
          <div className="layout_content_box AddCatalog_whitebox">
            <Form
              className="form_block_main add-catalog-form"
              initialValues={{ ...report}}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              scrollToFirstError
            >
              <div className="formitem_row">
                
                <Form.Item
                  label="Company Of Origin"
                  name="company_of_origin"
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please Enter Company Of Origin!',
                    },
                    {
                      max: 6,
                      message: `${validationMessage}6`
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ] : [{
                    max: 6,
                    message: `${validationMessage}6`
                  }]}
                >
                  <Input
                    maxLength={6}
                    placeholder="Enter Company Of Origin"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                
                <Form.Item
                  label="Part Number"
                  name="part_number"
                  rules={ [
                    {
                      required: true,
                      message: 'Please Enter Part Number!',
                    },
                  ]}
                >
                  <Input
                    maxLength={25}
                    placeholder="Enter Part Number"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
             
              <div className="formitem_row">
                {isAddPart ? <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Can Sample',
                    }
                  ]}
                  label="Can Sample"
                  name="can_sample"
                >
                  <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="Can Sample" style={{ width: '100%' }}>
                    <Option value="1">Yes</Option>
                    <Option value="0">No</Option>
                  </Select>
                </Form.Item> :
                  <Form.Item
                    label="Can Sample"
                    name="can_sample"
                  >
                    <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="Can Sample" style={{ width: '100%' }}
                      disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>}
                
                
              </div>
              <div className="formitem_row">
                
                <Form.Item
                  label="Core Qty"
                  name="core_qty"
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please Enter core qty!',
                    },
                    {
                      max: 25,
                      message: `${validationMessage}6`
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ] : [{
                    max: 25,
                    message: `${validationMessage}6`
                  }]}
                >
                  <Input
                    maxLength={25}
                    placeholder="Enter Core Qty"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                
                <Form.Item
                  label="Consign Qty"
                  name="consign_qty"
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please Enter consign qty!',
                    },
                    {
                      max: 25,
                      message: `${validationMessage}6`
                    },
                    {
                      whitespace: true,
                      message: "Whitespace not allowed!"
                    }
                  ] : [{
                    max: 25,
                    message: `${validationMessage}6`
                  }]}
                >
                  <Input
                    maxLength={25}
                    placeholder="Enter Consign Qty"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                
                <Form.Item
                  label="Product Family"
                  name="prod_family"
                  rules={ [
                    {
                      required: true,
                      message: 'Please Enter Product Family!',
                    },
                  ]}
                >
                  <Input
                    maxLength={25}
                    placeholder="Enter Product Family"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
                </div>
                <div className="formitem_row">
                <Form.Item
                  label="Market Family"
                  name="market_family"
                  rules={ [
                    {
                      required: true,
                      message: 'Please Enter Market Family!',
                    },
                  ]}
                >
                  <Input
                    maxLength={25}
                    placeholder="Enter Market Family"
                    size="large"
                    disabled={isUser ? isDisabled(userRole) && !isAddPart : false}
                  />
                </Form.Item>
              </div>
             <div className="formitem_row">
                <Form.Item
                  name="load_date"
                  label="Load Date"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Date!',
                    },
                    ]}
                
                >
                  <DatePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                        report &&
                        report.load_date &&
                      moment(report.load_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"

                  />
                </Form.Item>
                <Form.Item
                  name="load_time"
                  label="Load Time"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Time!',
                    },
                    ]}
                
                >
                  <TimePicker
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    defaultValue={
                        report&&
                        report.update_time &&
                      moment(report.load_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"

                  />
                </Form.Item>
              </div>
             <div className="formitem_action_row" id="bottom">
                <Form.Item>
                  <Button size="large" onClick={() => this.props.setReportStatus('', '')}>
                    Back
                  </Button>
                  {isPermission(
-                    PERMISSIONS_BY_NAME[
                        ('Can add dk inventory report','Manage DK Inventory Report')
                      ]
                    ) && (
                      <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                  </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
         
        </div>
      </div>
    )
  }
}

export default DkInventoryForm
