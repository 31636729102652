import { ExportToCsv } from 'export-to-csv'
import { ROLES_BY_NAME, PERMISSIONS_BY_NAME } from './constant'
import ExportJsonExcel from 'js-export-excel'

//function to clear local storage
export const clearStorage = () => {
  localStorage.clear()
}

//function to export/download excel sheets
export const downloadExcel = (filename, data, headers = []) => {
  let option = {}

  let wscols = []
  for (let i = 0; i < headers.length; i++) {
    // columns length added
    wscols.push('8')
  }

  option.datas = [
    {
      sheetData: data,
      sheetName: filename,
      sheetHeader: headers,
      fieldSeparator: ',',
      decimalSeparator: '.',
      columnWidths: wscols,
    },
  ]

  let toExcel = new ExportJsonExcel(option)
  toExcel.saveExcel(data)
}

//function to download export/download CSV sheet
export const exportCSVFile = (filename, data, headers = []) => {
  const options = {
    fieldSeparator: ',',
    filename: filename,
    decimalSeparator: '.',
    showLabels: true,
    headers,
    // Won't work with useKeysAsHeaders present!
  }
  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data)
}

//function to download export/download Text file
export const exportTextFile = async (filename, fileContent) => {
  let element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

//function to check if user permission
export const isPermission = (permission, isViewOnly = false) => {
  let userDetail = JSON.parse(localStorage.getItem('userDetail')) || {}
  let userRole = userDetail.role
  let userPermission =
    userDetail.permissions ||
    [
      // PERMISSIONS_BY_NAME['Add/Edit catalog list'],
      // PERMISSIONS_BY_NAME['Add/Edit user list'],
      // PERMISSIONS_BY_NAME['Edit business rules'],
      // PERMISSIONS_BY_NAME['Edit sample order list'],
      // PERMISSIONS_BY_NAME['Manual cancellation'],
      // PERMISSIONS_BY_NAME['Reprocess sample order'],
      // PERMISSIONS_BY_NAME['Setup catalog import setting'],
      // PERMISSIONS_BY_NAME['Setup import/export settings'],
      // PERMISSIONS_BY_NAME['View business rules'],
      // PERMISSIONS_BY_NAME['View catalog list'],
      // PERMISSIONS_BY_NAME['View sample order list'],
      // PERMISSIONS_BY_NAME['View user list'],
      // PERMISSIONS_BY_NAME['View/Edit user permissions'],
    ]
  if (userRole === ROLES_BY_NAME['System Admin']) {
    return true
  } else if (
    [
      ROLES_BY_NAME['Order Intake user'],
      ROLES_BY_NAME['Order fulfilment user'],
      ROLES_BY_NAME['Process Admin'],
      ROLES_BY_NAME['View only'],
    ].includes(userRole)
  ) {
    if (userPermission.includes(permission)) return true
    else return false
  } else if (permission == '') {
    return true
  }
}

export const trimSpace = (obj) => {
  for (let key in obj) {
    obj[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key]
  }
  return obj
}

//function to disable fields for specific users in forms
export const isDisabled = (permission) => {
  if (
    permission == 'Order Intake user' ||
    permission == 'Order fulfilment user' ||
    permission == 'Process Admin' ||
    permission == 'View only'
  ) {
    return true
  } else {
    return false
  }
}

export const testAscii = (str) => {
  return /^[\000-\177]*$/.test(str)
}

//function to validate route access
export const isRoutePermitted = (path) => {
  let permitted = false
  switch (path) {
    case '/sampleorder':
      permitted = isPermission(PERMISSIONS_BY_NAME['View Sample Order'])
      break
    case '/usermanagement':
      permitted = isPermission(PERMISSIONS_BY_NAME['View User Management'])
      break
    case '/catalogmanagement':
      permitted = isPermission(PERMISSIONS_BY_NAME['View Catalog import'])
      break
    case '/businessrules':
      permitted = isPermission(PERMISSIONS_BY_NAME['View Business Rules'])
      break
    case '/managedistribution':
      permitted = isPermission(PERMISSIONS_BY_NAME['View Distribution list'])
      break
    case '/ftpsettings':
      permitted = isPermission(PERMISSIONS_BY_NAME['View FTP settings'])
      break
    case '/sftpsettings':
      permitted = isPermission(PERMISSIONS_BY_NAME['View FTP settings'])
      break
    case '/customerchannel':
      permitted = isPermission(PERMISSIONS_BY_NAME['View Customer channel'])
      break
    case '/userroles':
      permitted = isPermission(
        PERMISSIONS_BY_NAME['View User Role permissions']
      )
      break
    case '/dplScreening':
      permitted = isPermission(PERMISSIONS_BY_NAME['View DPL'])
      break
    case '/dkinventory':
      permitted = true
      break
    case '/dkreport':
      permitted = isPermission(PERMISSIONS_BY_NAME['View DK Inventory Report'])
      break
    case '/eusholdrelease':
      permitted = isPermission(PERMISSIONS_BY_NAME['EUS Hold Release'])
      break
    case '/erpordernumber':
      permitted = true
      break
    case '/editprofile':
      permitted = true
      break
    case '/sampleOrderAudit':
      permitted = true
      break
    case '/productfamily':
      permitted = true
      break
  }
  return permitted
}
