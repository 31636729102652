import React, { Component } from 'react'
import { Table, Layout, Form, Input, Button, Breadcrumb, Select, Upload, DatePicker, TimePicker, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import './ImportCatalog.scss'
import HistoryTable from '../../components/HistoryTable/HistoryTable'
import { images } from "../../config/images"
import { NavLink } from 'react-router-dom'
import { HTTPService } from '../../otherServices/apiService'
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from '../../otherServices/constant';
const { Sider, Content } = Layout;

const { Option } = Select;

class ImportCatalog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formData: null
    }
    this.getFormData = this.getFormData.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
  }

  componentDidMount() {
    this.getFormData()
  }
  async getFormData() {
    const data = { "ftp_purpose": "CatalogImport" };
    try {
      let userRes = await HTTPService({
        method: 'POST',
        url: 'ftp-detail/',
        data: {
          ...data
        }
      })
      let startDate = moment(userRes.data.start_date)
      let starTime = moment(userRes.data.start_time, TIME_FORMAT)
      userRes.data.start_date = startDate;
      userRes.data.start_time = starTime;
      this.setState({
        formData: userRes.data
      })

    }
    catch (ex) {
      message.error('Failed to Update')
    }

  }

  async handleFinish(values) {
    values.ftp_purpose = "CatalogImport"

    let startDate = values.start_date;
    let startTime = values.start_time;
    values.start_date = moment(startDate).format(DATE_FORMAT)
    values.start_time = moment(startTime).format(TIME_FORMAT)

    try {
      let userRes = await HTTPService({
        method: 'POST',
        url: 'ftp-detail/save/',
        data: {
          ...values
        }
      }).then(message.success('Form submited'))

    }
    catch (error) {
      message.error('Failed to Update', error)
    }

  }

  renderForm() {
    return (
      <div className="ImportCatalogStyles">
        <div className="ImportCatalog_container">
          <div className="ImportCatalog__title">Catalog Import</div>
          <div className="layout_content_box ImportCatalog_whitebox">
            <Form
              className="form_block_main import-catalog-form"
              initialValues={this.state.formData ? { ...this.state.formData } : null}
              onFinish={(values) => this.handleFinish(values)}
            >
              <div className="formitem_row">
                <Form.Item
                  label="Catalog Import"
                  name="ftp_folder_catalog_import"
                >
                  <Input
                    placeholder="ftp catalog import"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label="Catalog Archive"
                  name="ftp_folder_catalog_archive"
                >
                  <Input
                    placeholder="ftp catalog archive"
                    size="large"
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Username" name="ftp_username" rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}>
                  <Input placeholder="John Doe" size="large" />
                </Form.Item>
                <Form.Item label="Email" name="email_output_to" rules={[
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                  {
                    whitespace: true,
                    message: 'Whitespace not allowed!'
                  }
                ]}>
                  <Input type="email" placeholder="JohnDoe@yopmail.com" size="large" />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Password" name="ftp_password" rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]} >
                  <Input.Password placeholder="********" size="large" />
                </Form.Item>
                <Form.Item label="Start Date" name="start_date" rules={[
                  {
                    required: true,
                    message: 'Please input your start_date!',
                  },
                ]}>
                  <DatePicker placeholder="YYYY-MM-DD" size="large" value={this.state.formData.start_date} />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Start Time" name="start_time" rules={[
                  {
                    required: true,
                    message: 'Please input your start_time!',
                  },
                ]}>
                  <TimePicker placeholder="HH:mm:ss" size="large" value={this.state.formData.start_time} />
                </Form.Item>
                <Form.Item label="Frequency" name="frequency" rules={[
                  {
                    required: true,
                    message: 'Please input your frequency!',
                  },
                ]}>
                  <Select defaultValue="1 Hours" style={{ width: '100%' }}>
                    <Option value="1 Hour">1 Hour</Option>
                    <Option value="2 Hours">2 Hour</Option>
                    <Option value="3 Hours">3 Hour</Option>
                    <Option value="4 Hours">4 Hour</Option>
                    <Option value="5 Hours">5 Hour</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_upload_row">
                <Form.Item label="Catalog File">
                  <Form.Item name="dragger" valuePropName="fileList">
                    <Upload.Dragger name="files" action="/upload.do">
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
              <div className="formitem_action_row">
                <Form.Item>
                  <Button size="large">
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size="large"
                  >
                    Process
                 </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="history_table_block">
            <HistoryTable />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (

      this.state.formData ? this.renderForm() : null

    )
  }
}

export default ImportCatalog







