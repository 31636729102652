import React, { Component } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  message,
  Spin,
  Checkbox,
  Switch,
} from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import './ImportSampleOrderForSFTP.scss'
import { HTTPService } from '../../otherServices/apiService'
import moment from 'moment'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import { trimSpace, isPermission } from '../../otherServices/commonFunction'

class ImportSampleOrderForSFTP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      loader: false,
      file: null,
      isDistributionData: false,
      users: [],
    }
    this.getFormData = this.getFormData.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
  }

  //function to fetch form data
  componentDidMount() {
    this.getFormData()
  }
  async getFormData() {
    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: `sftp-detail/?sftp_purpose=${this.props.sftp_purpose}`,
      })
      if (userRes.success) {
        let startDate = moment(userRes.data.start_date)
        let starTime = moment(userRes.data.start_time, TIME_FORMAT)
        userRes.data.start_date = startDate
        userRes.data.start_time = starTime
        this.setState({
          formData: userRes.data,
        })
        this.setState({ loader: false })
      } else {
        message.error(userRes.message)
        this.setState({ loader: false })
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Failed to Fetch')
    }
  }

  //function to submit form
  async handleFinish(values) {
    let valueData = trimSpace(values)
    this.setState({ loader: true })
    valueData.sftp_purpose = this.props.sftp_purpose
    let startDate = valueData.start_date
    let startTime = valueData.start_time
    valueData.start_date = moment(startDate).format(DATE_FORMAT)
    valueData.start_time = moment(startTime).format(TIME_FORMAT)
    valueData.dragger = ''
    valueData.ftp_url = valueData.ftp_url || ''
    valueData.manual_upload_file = this.state.file
    let formData = new FormData()
    for (let key in valueData) {
      formData.set(key, valueData[key])
    }
    try {
      let userRes = await HTTPService({
        method: 'POST',
        url: 'sftp-detail/save/',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (userRes.success) {
        this.getFormData()
        message.success('Form Submited Successfully')
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Failed to Update', error)
    }
  }

  renderFields() {
    const sftp_purpose = this.props.sftp_purpose
    let archiveFolder
    let folderName
    let lableName1
    let lableName2
    if (sftp_purpose === 'GISExport') {
      archiveFolder = 'sftp_archive_folder'
      folderName = 'sftp_folder'
      lableName1 = 'GIS Export'
      lableName2 = 'GIS Export Archive'
    }

    return (
      <div className="formitem_row">
        <Form.Item
          label={lableName1}
          name={folderName}
          rules={[
            {
              required: true,
              message: `Please input your ${lableName1} !`,
            },
          ]}
        >
          <Input placeholder={lableName1} size="large" />
        </Form.Item>
        <Form.Item
          label={lableName2}
          name={archiveFolder}
          rules={[
            {
              required: true,
              message: `Please input your ${lableName2} !`,
            },
          ]}
        >
          <Input placeholder={lableName2} size="large" />
        </Form.Item>
      </div>
    )
  }

  async handleChanges({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
    this.setState({ file })
  }

  handleChange = (selectedItems) => {
    this.setState({ selectedItems })
  }

  onFinishFailed = (errors) => {
    message.error('Enter the required fields!')
  }

  renderForm() {
    const { selectedItems } = this.state
    const { users } = this.props
    return (
      <div className="ImportSampleOrderStyles">
        <div className="ImportSampleOrder_container">
          <div className="ImportSampleOrder__title">{this.props.tabTitle}</div>
          <div className="layout_content_box ImportSampleOrder_whitebox">
            <Form
              className="form_block_main import-sample-order-form"
              initialValues={this.state.formData}
              onFinish={this.handleFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <div className="formitem_row">
                <Form.Item
                  name="export_to_gis"
                  label="Export To GIS"
                  valuePropName="checked"
                >
                  <Checkbox> Export To GIS</Checkbox>
                </Form.Item>
              </div>
              {this.renderFields()}
              <div className="formitem_row">
                <Form.Item
                  label="Username"
                  name="sftp_username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input placeholder="John Doe" size="large" />
                </Form.Item>
                <Form.Item
                  name="email_output_to"
                  label="Select distribution List"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Dstribution group/ Enter MailID!',
                    },
                  ]}
                >
                  <Select
                    className="multiple_select"
                    mode="tags"
                    placeholder="Select Dstribution group or Enter MailID"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    value={selectedItems}
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option && option.children
                        ? option.children.indexOf(input.toLowerCase()) >= 0
                        : null
                    }
                    tokenSeparators={[',']}
                  >
                    {users.length &&
                      users.map((item) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              {/*new fields */}
              <div className="formitem_row">
                <Form.Item name="sftp_port" label="Port">
                  <Input placeholder="Enter Port" type="text" />
                </Form.Item>
                <Form.Item name="sftp_url" label="URL">
                  <Input placeholder="Enter URL" type="text" />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Password"
                  name="sftp_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="********"
                    size="large"
                    type="password"
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="send_success_logs"
                  label="Send logs for success"
                  valuePropName="checked"
                >
                  <Checkbox> Send logs for success</Checkbox>
                </Form.Item>
                <Form.Item
                  name="api_to_s4"
                  label="Api To S4"
                  valuePropName="checked"
                  hidden={true}
                >
                  <Switch>Api To S4</Switch>
                </Form.Item>
              </div>

              <div className="formitem_upload_row">
                <Form.Item label="Sample Order File">
                  <Form.Item name="dragger">
                    <Upload.Dragger
                      customRequest={(info) => this.handleChanges(info)}
                      accept=".txt"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </div>

              {isPermission(
                //check for save permission
                PERMISSIONS_BY_NAME['Manage FTP settings']
              ) && (
                <div className="formitem_action_row">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.state.loader ? (
      <div className="loader">
        <Spin />
      </div>
    ) : (
      <div>{this.renderForm()}</div>
    )
  }
}

export default ImportSampleOrderForSFTP
