import React, { useState, Fragment } from 'react'
import { Table, Popconfirm, Select, Button } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import './HistoryTable.scss'

const columns = [
  {
    title: 'File Name',
    dataIndex: 'filename',
    width: '30%',
    render: (text) => <Link className="filename">{text}</Link>,
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    width: '20%',
    render: (date) => <h4>{date.substring(0, 10)}</h4>,
  },
  {
    title: 'Time',
    dataIndex: 'created_at',
    width: '20%',
    render: (text) => <h4>{text.substring(11, 19)}</h4>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '20%',
    render: (text) => <Link className="status success">{text}</Link>,
  },
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    name: record.name,
  }),
}

const HistoryTable = (props) => {
  const [selectionType, setSelectionType] = useState('checkbox')
  return (
    <div className="HistoryTableStyles">
      <div className="HistoryTable_container">
        <div className="Table_top_row">
          <div className="Table_heading">{props.titleName}</div>
        </div>
        <div className="layout_content_box CatalogTable_whitebox">
          <Table
            className="Table_block_main"
            columns={columns}
            dataSource={props.data}
            rowKey="filename"
          />
        </div>
      </div>
    </div>
  )
}

export default HistoryTable
