
//constants for page ordering
export const USER_SORTING = 'ordering=-id'
export const CATALOG_SORTING = 'ordering=-id'
export const ORDER_SORTING = 'ordering=-id'
export const REPORT_SORTING = 'ordering=-id'
export const PAGE_LIMIT = 20

//constants for date and time formats
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm:ss'

//user roles by id
export const ROLES = {
  'View only': 2,
  'System Admin': 1,
  'Order Intake user': 3,
  'Order fulfilment user': 4,
  'Process Admin': 5,
  
}

//user roles by name
export const ROLES_BY_NAME = {
  'View only': 'View only',
  'System Admin': 'System Admin',
  'Order Intake user': 'Order Intake user',
  'Order fulfilment user': 'Order fulfilment user',
  'Process Admin': 'Process Admin',
  
}

//user permission by id
export const PERMISSIONS = {
  'Manage User Management': "99",
  'View User Management': "36",
  'Manage Sample Order': "93",
  'View Sample Order': "32",
  'Manage Catalog import': "94",
  'View Catalog import': "28",
  'Manage User Role permissions': "100",
  'View User Role permissions': "12",
  'Manage Business Rules': "95",
  'View Business Rules': "24",
  'Manage FTP settings': "96",
  'View FTP settings': "52",
  'Manage Distribution list': "97",
  'View Distribution list': "92",
  'Manage Customer channel': "98",
  'View Customer channel': "88",
  'Reprocess': '101',
  'Reprocess bypass disti': '102',
  'Add sample order': '29',
  'Add Part': '25',
  // 'Delete user': '35',
  'Delete sample order': '31',
  'Delete catalog': '27',
  'Edit DPL': '104',
  'View DPL': '106',
  'Mass DPL Release': '107',
  'Mass Cancel Orders': '108',
  'View DK Inventory Report': '113',
  'Manage DK Inventory Report' : '125',
  'Can add dk inventory report': '110',
  'Can delete dk inventory report': '112',
  'EUS Hold Release': '126',
  
}

//user permissions by name
export const PERMISSIONS_BY_NAME = {
  'Manage User Management': 'Manage User Management',
  'View User Management': 'View User Management',
  'Manage Sample Order': 'Manage Sample Order',
  'View Sample Order': 'View Sample Order',
  'Manage Catalog import': 'Manage Catalog import',
  'View Catalog import': 'View Catalog import',
  'Manage User Role permissions': 'Manage User Role permissions',
  'View User Role permissions': 'View User Role permissions',
  'Manage Business Rules': 'Manage Business Rules',
  'View Business Rules': 'View Business Rules',
  'Manage FTP settings': 'Manage FTP settings',
  'View FTP settings': 'View FTP settings',
  'Manage Distribution list': 'Manage Distribution list',
  'View Distribution list': 'View Distribution list',
  'Manage Customer channel': 'Manage Customer channel',
  'View Customer channel': 'View Customer channel',
  'Reprocess': 'Reprocess',
  'Reprocess bypass disti': 'Reprocess bypass disti',
  'Add sample order': 'Add sample order',
  'Add Part': 'Add Part',
  // 'Delete user': 'Delete user',
  'Delete sample order': 'Delete sample order',
  'Delete catalog': 'Delete catalog',
  'Edit DPL': 'Edit DPL',
  'View DPL': 'View DPL',
  'Mass DPL Release': 'Mass DPL Release',
  'Mass Cancel Orders': 'Mass Cancel Orders',
  'Delete sample Orders': 'Delete sample Orders',
  'Delete catalogs': 'Delete catalogs',
  'View DKInventory': 'View DKInventory',
  'View DK Inventory Report': 'View DK Inventory Report',
  'Manage DK Inventory Report': 'Manage DK Inventory Report',
  'Can add dk inventory report': 'Can add dk inventory report',
  'Can delete dk inventory report': 'Can delete dk inventory report',
  'EUS Hold Release': 'EUS Hold Release'
}