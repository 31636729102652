import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import './ERPOrderNumber.scss'
import { withRouter } from 'react-router-dom'
import DPLscreeningTable from '../../components/DPLscreeningTable/DPLscreeningTable'
import ERPOrderNumber from '../../components/ERPOrderNumber/ERPOrderNumber'
const { Content } = Layout

class DKInventory extends Component {
  render() {
    return (
      <div className="CatalogManagementStyles">
        <ERPOrderNumber />
      </div>
    )
  }
}

export default withRouter(DKInventory)
