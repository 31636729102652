import React, { Component } from 'react'
import { HTTPService } from '../../otherServices/apiService'
import './ERPOrderNumber.scss'
import { InboxOutlined } from '@ant-design/icons'
import { Form, Input, Button, message, Table, Upload, Spin } from 'antd'
import { trimSpace, isPermission } from '../../otherServices/commonFunction'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  PERMISSIONS_BY_NAME,
  PAGE_LIMIT,
  USER_SORTING,
} from '../../otherServices/constant'
import moment from 'moment'

class ERPOrderNumber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      loader: false,
      file: null,
      isDistributionData: false,
      users: [],
    }
  this.handleFinish = this.handleFinish.bind(this)

}
  async handleFinish(values,file) {
    let valueData = trimSpace(values)
    let { eus_hold_release_file, ...otherData } = valueData
    this.setState({ loader: true })
    let formData = new FormData()
    formData.append('fileUpload', this.state.file);
    for (let key in valueData) {
      formData.set(key, valueData[key])
    }
    try {
      let userRes = await HTTPService({
        method: 'POST',
        url: 'update-erp-order-number/',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (userRes.success) {
        message.success('Updating ERP Order Number in background. Email notification will be sent')
        this.setState({ loader: false})
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Releasing ERP Order Number Failed')
    }
  }
  
  async handleChanges({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
    this.setState({ file })
  }

  handleChange = (selectedItems) => {
    this.setState({ selectedItems })
  }
 
  onFinishFailed = (errors) => {
     message.error('Enter the required fields!')
  }

renderForm() {
  return (
    <div className="BusinessRules_container">
      <div className="layout_content_box BusinessRules_whitebox">
        <Form
          className="form_block_main business-rules-form"
          initialValues={this.state.formData}
          onFinish={this.handleFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <div className="formitem_upload_row">
          <Form.Item label="Update ERP Order Number File">
                  <Form.Item name="dragger">
                    <Upload.Dragger
                      customRequest={(info) => this.handleChanges(info)}
                      accept=".txt"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
          </div>
          <div className="formitem_action_row">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}
render() {
  // return this.state.loader ? (
  //   <div className="loader">
  //     <Spin />
  //   </div>
  // ) : this.state.formData ? (
  //   <div>{this.renderForm()}</div>
  // ) : <Spin />
  return this.state.loader ? (
    <div className="loader">
      <Spin />
    </div>
  ) : (
    <div>{this.renderForm()}</div>
  )
}
}

export default ERPOrderNumber
