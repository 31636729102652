import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import './CatalogManagement.scss'
import { withRouter } from 'react-router-dom'
import CatalogTableEdit from '../../components/CatalogTableEdit/CatalogTableEdit'
const { Content } = Layout

class CatalogManagement extends Component {
  render() {
    return (
      <div className="CatalogManagementStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Catalog Management</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <CatalogTableEdit />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default withRouter(CatalogManagement)
