import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import './SampleOrderAudit.scss'
import { withRouter } from 'react-router-dom'
import AuditTable from '../../components/SampleOrderAudit/SampleOrderAudit'
const { Content } = Layout

class SampleOrderAudit extends Component {
  render() {
    return (
      <div className="CatalogManagementStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Sample Order Audit</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <AuditTable history={this.props.history} />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default withRouter(SampleOrderAudit)
