import React from 'react'
import { Table, Button, Form, Checkbox, message, Spin } from 'antd'
import { Link } from 'react-router-dom'
import './UserRoleTable.scss'
import { HTTPService } from '../../otherServices/apiService'
import { withRouter } from 'react-router-dom'
import { ROLES } from '../../otherServices/constant'
import { PERMISSIONS, PERMISSIONS_BY_NAME } from '../../otherServices/constant'
import { isPermission } from '../../otherServices/commonFunction'

let rolePermissionConst = {
  [ROLES['System Admin']]: [],
  [ROLES['Order Intake user']]: [],
  [ROLES['Order fulfilment user']]: [],
  [ROLES['Process Admin']]: [],
  
}

class UserRoleTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      roles: null,
      columns: [],
      permissions: null,
      loader: false,
      rolePermissionObj: { ...rolePermissionConst },
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  //function to fetch user roles and permissions
  async fetchData() {
    this.setState({ loader: true })

    Promise.all([
      HTTPService({
        method: 'GET',
        url: 'user-role/',
      }),
      HTTPService({
        method: 'GET',
        url: 'permission/',
      }),
    ]).then(([userPermissions, permission]) => {
      let userPermissionsAraay = userPermissions.data.results
      let rolePermissionObj = {}
      for (let up of userPermissionsAraay) {
        rolePermissionObj[up.id] = []
        for (let permission of up.permissions) {
          if (PERMISSIONS[permission]) {
            rolePermissionObj[up.id].push(PERMISSIONS[permission])
          }
        }
      }
      let userRoles = userPermissions.data.results
      userRoles.sort((role1, role2) => role1.id - role2.id)
      let roleColumns = []
      for (let role of userRoles) {
        if (['System Admin'].includes(role.role)) {
          roleColumns.push({
            title: role.role,
            dataIndex: role.role,
            render: (text, value) => (
              <Form.Item valuePropName="checked" noStyle>
                <Checkbox
                  disabled="disabled"
                  checked={true}
                ></Checkbox>
              </Form.Item>
            )
          })
        } else {
          roleColumns.push({
            title: role.role,
            dataIndex: role.role,
            render: (text, permission) => {
              return (
                <Form.Item valuePropName="checked" noStyle>
                  <Checkbox
                    onChange={(e) => {
                      this.handleChange(permission, role.id, e.target.checked)
                    }}
                    defaultChecked={
                      rolePermissionObj[role.id].includes(PERMISSIONS[permission.name])
                        ? true
                        : false
                    }
                  ></Checkbox>
                </Form.Item>
              )
            }
          })
        }
      }

      const columns = [
        {
          title: '',
          dataIndex: 'name',
          render: (text) => <Link className="username">{text}</Link>,
        },
        ...roleColumns,
      ]

      this.setState({
        permissions: permission.data.results,
        roles: userPermissions.data.results,
        loader: false,
        columns,
        rolePermissionObj: { ...rolePermissionObj },
      })
    })
  }

  //function to call API to save permission changes
  async handleSubmit() {
    let { rolePermissionObj } = this.state
    delete rolePermissionObj['1']
    // delete rolePermissionObj['5']
    let rolePermissionArray = []
    let roleKeys = Object.keys(rolePermissionObj)
    for (let roleKey of roleKeys) {
      rolePermissionArray.push({
        role: roleKey,
        permissions: rolePermissionObj[roleKey].map(Number),
      })
    }
    if (rolePermissionArray.length === 0) {
      message.info('Selec one permission')
      return
    }
    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'PUT',
        url: 'user-role',
        data: rolePermissionArray,
      })
      if (userRes.success) {
        this.fetchData()
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (ex) {
      message.error('Failed to Update')
      this.setState({ loader: false })
    }
  }

  //function to handle checkbox check-uncheck
  handleChange = (permission, roleId, isPermissionChecked) => {
    let { rolePermissionObj } = this.state
    if (isPermissionChecked) {
      let permissions = new Set([...rolePermissionObj[roleId], permission.id])
      rolePermissionObj[roleId] = [...permissions]
    } else {
      let permissions = rolePermissionObj[roleId].filter(
        (id) => {
          return id != permission.id
        }
      )
      rolePermissionObj[roleId] = [...permissions]
    }
  }

  render() {
    const { columns, permissions } = this.state
    return (
      <div className="UserRoleTableStyles">
        <div className="TableEdit_container">
          <div className="Table_top_row">
            <div className="Table_heading">
              Authorizations permitted by user roles
            </div>
            {/* {isPermission() && ( */}
            <div className="Table_action_btns">
              {isPermission(
                PERMISSIONS_BY_NAME['Manage User Role permissions']) && <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    this.handleSubmit()
                  }}
                >
                  <span>Save</span>
                </Button>}
            </div>
            {/* // )} */}
          </div>
          <div className="layout_content_box UserRoleTable_whitebox">
            {this.state.loader ? (
              <div className="loader">
                <Spin size="large" />
              </div>
            ) : (
                <Table
                  className="Table_block_main"
                  columns={columns}
                  dataSource={permissions}
                  pagination={false}
                  rowKey='name'
                />
              )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(UserRoleTable)
