import { lazy } from 'react'

//route paths and properties

export const routes = {
  login: {
    path: '/login',
    component: lazy(() => import('./pages/Login/Login')),
    exact: true,
  },
  register: {
    path: '/register',
    component: lazy(() => import('./pages/Register/Register')),
    exact: true,
  },
  forgotpassword: {
    path: '/forgotpassword',
    component: lazy(() => import('./pages/ForgotPassword/ForgotPassword')),
    exact: true,
  },

  usermanagement: {
    path: '/usermanagement',
    component: lazy(() => import('./pages/UserManagement/UserManagement')),
    exact: true,
  },
  catalogmanagement: {
    path: '/catalogmanagement',
    component: lazy(() =>
      import('./pages/CatalogManagement/CatalogManagement')
    ),
    exact: true,
  },
  sampleorder: {
    path: '/sampleorder',
    component: lazy(() => import('./pages/SampleOrder/SampleOrder')),
    exact: true,
  },
  businessrules: {
    path: '/businessrules',
    component: lazy(() =>
      import('./components/BusinessRulesTable/BusinessRulesTable')
    ),
    exact: true,
  },
  editprofile: {
    path: '/editprofile',
    component: lazy(() => import('./pages/EditProfile/EditProfile')),
    exact: true,
  },
  yourorders: {
    path: '/yourorders',
    component: lazy(() => import('./pages/YourOrders/YourOrders')),
    exact: true,
  },
  userroles: {
    path: '/userroles',
    component: lazy(() => import('./pages/UserRoles/UserRoles')),
    exact: true,
  },
  managedistribution: {
    path: '/managedistribution',
    component: lazy(() =>
      import('./pages/ManageDistribution/ManageDistribution')
    ),
    exact: true,
  },
  ftpsettings: {
    path: '/ftpsettings',
    component: lazy(() => import('./pages/FTSSettings/FTSSettings')),
    exact: true,
  },
  sftpsettings: {
    path: '/sftpsettings',
    component: lazy(() => import('./pages/SFTPSettings/SFTPSettings')),
    exact: true,
  },
  customerchannel: {
    path: '/customerchannel',
    component: lazy(() => import('./pages/CustomerChannel/CustomerChannel')),
    exact: true,
  },
  dplScreening: {
    path: '/dplScreening',
    component: lazy(() => import('./pages/DPLscreening/DPLscreening')),
    exact: true,
  },
  dkInventoryReport: {
    path: '/dkreport',
    component: lazy(() =>
      import('./pages/DKInventoryReport/DKInventoryReport')
    ),
    exact: true,
  },
  dklInventory: {
    path: '/dkinventory',
    component: lazy(() => import('./pages/DkInventory/DKInventory')),
    exact: true,
  },
  eusholdrelease: {
    path: '/eusholdrelease',
    component: lazy(() => import('./pages/EUSHoldRelease/EUSHoldRelease')),
    exact: true,
  },
  erpordernumber: {
    path: '/erpordernumber',
    component: lazy(() => import('./pages/ERPOrderNumber/ERPOrderNumber')),
    exact: true,
  },
  sampleOrderAudit: {
    path: '/sampleOrderAudit',
    component: lazy(() => import('./pages/SampleOrderAudit/SampleOrderAudit')),
    exact: true,
  },
  productFamily: {
    path: '/productfamily',
    component: lazy(() => import('./pages/ProductFamily/ProductFamily')),
    exact: true,
  },

  NoAccessPage: {
    // path: '*',
    component: lazy(() => import('./pages/NoAccessPage/NoAccessPage')),
    exact: true,
  },
}

export const renderRoutes = Object.entries(routes)
