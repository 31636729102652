import React, { Component } from 'react'
import moment from 'moment'
import {
  Input,
  Button,
  Form,
  message,
  DatePicker,
  TimePicker,
  Select,
  Spin,
} from 'antd'
import { DATE_FORMAT, TIME_FORMAT, PERMISSIONS_BY_NAME } from '../../otherServices/constant'
import { HTTPService } from '../../otherServices/apiService'
import { trimSpace, isPermission, isDisabled, testAscii, exportCSVFile,
    downloadExcel,
   } from '../../otherServices/commonFunction'
const { Option } = Select
class SimpleTableForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: props.record ? this.toMoment(props.record) : props.record,
      processLoader: false,
      isUser: true,
    };
  }
  componentDidMount() {
    let role = localStorage.getItem('role')
    if (role == 'System Admin') {
      this.setState({ isUser: false })
    }
  }
  toMoment = (record) => {
    if (record.load_time) {
        record.load_time = moment(
          record.load_time,
          TIME_FORMAT
        )
      }
      if (record.load_date) {
        record.load_date = moment(
          record.load_date,
        )
      }
    return record
  }

  onFinish = async (values) => {
    this.setState({ processLoader: true })
    let record = { ...values, id: this.state.record && this.state.record.id }
    let recordData = trimSpace(record);

    if (!record.id) {
      let res;
      try {
        res = await HTTPService({
          method: 'POST',
          url: 'customer-channel/',
          data: { ...recordData },
        })
        if (res.success) {
          message.success("Added Successfully")
          this.props.onChangePageNumber(1, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ record: recordData })
        }

      } catch{
        message.error("Failed to Add Part")
        this.setState({ record: recordData })
      }

    } else {
      let res;
      try {
        res = await HTTPService({
          method: 'PUT',
          url: `customer-channel/${record.id}/`,
          data: { ...record },
        })
        if (res.success) {
          message.success("Updated Successfully")
          this.props.onChangePageNumber(this.props.currentPage, this.props.pageLimit)
        } else {
          message.error(res.message)
          this.setState({ record: recordData })
        }

      } catch{
        message.error("Failed to Update")
        this.setState({ record: recordData })
      }
    }
    this.setState({ processLoader: false })
  }

  onFinishFailed = (errors) => {
    message.error("Enter the required fields!")
  }
render() {
    const { record= {}, processLoader, isUser } = this.state;
    const validationMessage = 'Maximum field value is '
    const { isAddPart } = this.props
    const userRole = localStorage.getItem('role')
    return (
      <div className="AddCatalogStyles">
        {processLoader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}

        <div className="AddCatalog_container">
          <div className="AddCatalog__title">
            {record? 'Edit' : 'Add' } Customer
          </div>
          <div className="layout_content_box AddCatalog_whitebox">
            <Form
              className="form_block_main add-catalog-form"
              initialValues={{ ...record}}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              scrollToFirstError
            >
              <div className="formitem_row">
                
              <Form.Item
                  label="Customer Name"
                  name="disti_name"
                  rules={isAddPart ? [
                    {
                      required: true,
                      message: 'Please input customer name!',
                    },
                    {
                      max: 35,
                      message: `${validationMessage}35`,
                    },
                    {
                      whitespace: true,
                      message: 'Whitespace not allowed!',
                    }
                  ] : [{
                    max: 35,
                    message: `${validationMessage}35`
                  }]}
                >
                  <Input 
                  placeholder="Customer Name" 
                  size="large" 
                  disabled={isUser ? !isDisabled(userRole) && !isAddPart : false} />
                </Form.Item>
                <Form.Item
                  name="is_stocking_rep"
                  label="Is stocking rep"
                  rules={[
                    {
                      required: true,
                      message: 'Please input customer name!',
                    },
                  ]}
                >
                  <Select 
                  placeholder="Stocking Rep" 
                  style={{ width: '100%' }}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}
                  >
                    <Option value="1">1</Option>
                    <Option value="0">0</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="tyt_distributors"
                  label="TYT Distributor"
                  rules={[
                    {
                      required: true,
                      message: 'Please input customer name!',
                    },
                  ]}
                >
                  <Select 
                  placeholder="Tyt Distributors" 
                  style={{ width: '100%' }}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              </div>
             <div className="formitem_action_row" id="bottom">
                <Form.Item>
                  <Button size="large" onClick={() => this.props.setReportStatus('', '')}>
                    Back
                  </Button>
                  {isPermission(
                    PERMISSIONS_BY_NAME[('Manage Customer channel')
                    ]
                  ) && (
                      <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                  </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
         
        </div>
      </div>
    )
  }
}

export default SimpleTableForm
