import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import { withRouter } from 'react-router-dom'
import './ManageDistribution.scss'
import ManageDistributionTable from '../../components/ManageDistributionTable/ManageDistributionTable'
const { Content } = Layout

class ManageDistribution extends Component {
  render() {
    return (
      <div className="ManageDistributionStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Manage Distribution</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <ManageDistributionTable />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default withRouter(ManageDistribution)
