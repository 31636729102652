import React, { Fragment } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import {
  Table,
  Popconfirm,
  Spin,
  message,
  Button,
  Input,
  Space,
  Form,
  Select,
} from 'antd'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import '../ManageDistributionTable/ManageDistributionTable.scss'
import { HTTPService } from '../../otherServices/apiService'
import { isPermission } from '../../otherServices/commonFunction'
import {
  CATALOG_SORTING,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import ProductFamilyForm from './ProductFamilyTableForm'
import ProductFamilyEmailForm from './ProductFamilyEmailTableForm'

class ProductFamilyTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      currentPage: 1,
      tableData: [],
      isFormOpen: false,
      currentData: null,
      emailData: null,
      isApiLoaded: false,
      searchText: null,
      filterApplied: null,
      isAddPart: false,
      filterValueArray: [],
      isFilter: false,
      pageLimit: 20,
      filterString: '',
      emails: [],
      isEmailFormOpen: false,
    }
  }
  componentDidMount = async () => {
    await this.fetchData()
    await this.getGpakSampleData()
  }

  //function for search functionality in table
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div className="search_filterDropdown">
        <Input
          className="search_input"
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            clearFilters()
            this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
          }}
        />
        <Space className="btns">
          <Button
            type="primary"
            onClick={() => {
              clearFilters()
              this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
            }}
            icon={<SearchOutlined />}
            size="medium"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              // clearFilters()
              this.onFilterReset(`${dataIndex}`)
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#3653AA' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#fafafa', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  //search function for columns
  onSearch = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      let stringSplit = value.split('=')
      if (i.includes(stringSplit[0])) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    arrayOfFilter.push(value)
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      filterString: `${arrayOfFilter.join('&&')}`,
    })
    this.getFilteredData(`${arrayOfFilter.join('&&')}`)
  }

  //function to reset applied filter
  onFilterReset = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      if (i.includes(value)) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    this.setState({ filterValueArray: arrayOfFilter, isFilter: true })
    if (arrayOfFilter.length == 0) {
      this.fetchData('')
    } else {
      this.setState({ filterString: `${arrayOfFilter.join('&&')}` })
      this.getFilteredData(`${arrayOfFilter.join('&&')}`)
    }
  }

  //function to fetch filtered data for table
  async getFilteredData(searchValue = '', currentPage = 1) {
    let urlLink = `product-family/?${searchValue}`
    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }

        this.setState({
          tableData: orders,
          loader: false,
          count: userRes.data.count,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
        })
        message.success('Filter Applied')
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      message.error('Filter Not Applied')
    }
  }

  //function to fetch table data
  fetchData = async (searchValue = '') => {
    this.setState({ loader: true })
    try {
      let res = await HTTPService({
        method: 'GET',
        url: `product-family/`,
      })
      if (res.success) {
        this.setState({
          tableData: res.data.results,
          count: res.data.count,
          loader: false,
        })
        if (res.data.results.length < 1) {
          message.success('No Product family Data Found')
        } else {
          message.success('Fetched Successfully')
        }
      } else {
        message.error(res.message)
        this.setState({
          tableData: [],
          count: 0,
          loader: false,
        })
      }
    } catch {
      this.setState({
        tableData: [],
        count: 0,
        loader: false,
      })
      message.error('Failed to Fetch')
    }
  }

  //function to toggle flag for Add/Edit form
  setFormStatus = (distributionData) => {
    this.setState({
      isFormOpen: !this.state.isFormOpen,
      currentData: distributionData ? distributionData : null,
    })
  }

  setEmailFormStatus = (distributionData) => {
    this.setState({
      isEmailFormOpen: !this.state.isEmailFormOpen,
      emailData: distributionData ? distributionData : null,
    })
  }

  //function to delete record
  handleDelete = async (key) => {
    try {
      let res = await HTTPService({
        url: `product-family/${key}/`,
        method: 'DELETE',
      })
      if (res.success) {
        this.fetchData('', this.state.currentPage)
      } else {
        this.fetchData('', this.state.currentPage)
      }
    } catch {
      this.fetchData('', this.state.currentPage)
    }
  }

  //function to fetch data on page change
  onChangePageNumber = async (pageNumber, PAGE_LIMIT) => {
    this.setState({ isApiLoaded: false, pageLimit: PAGE_LIMIT })
    let offset = (pageNumber - 1) * PAGE_LIMIT
    try {
      let res
      if (this.state.isFilter) {
        let searchText = this.state.searchText
        res = await HTTPService({
          method: 'GET',
          url: `product-family/?${searchText}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      } else {
        res = await HTTPService({
          method: 'GET',
          url: `product-family/?${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      }
      if (res.success) {
        let distributionDatas = [],
          count = 0
        for (let elemet of res.data.results) {
          distributionDatas.push({ ...elemet, key: elemet.id })
        }

        count = res.data.count
        this.setState({
          tableData: distributionDatas,
          count,
          currentPage: pageNumber,
          currentData: null,
          isFormOpen: false,
          isApiLoaded: true,
        })
      } else {
        message.error(res.message)
        this.setState({
          isFormOpen: false,
          isApiLoaded: true,
        })
      }
    } catch {
      message.error('Failed to fetch')
      this.setState({
        isFormOpen: false,
        isApiLoaded: true,
      })
    }
  }

  onChangeEmailPageNumber = async (pageNumber, PAGE_LIMIT) => {
    this.setState({ isApiLoaded: false, pageLimit: PAGE_LIMIT })
    let offset = (pageNumber - 1) * PAGE_LIMIT
    try {
      let res
      if (this.state.isFilter) {
        let searchText = this.state.searchText
        res = await HTTPService({
          method: 'GET',
          url: `distribution-list-gpak/?${searchText}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      } else {
        res = await HTTPService({
          method: 'GET',
          url: `distribution-list-gpak/?${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      }
      if (res.success) {
        let distributionDatas = [],
          count = 0
        for (let elemet of res.data.results) {
          distributionDatas.push({ ...elemet, key: elemet.id })
        }

        count = res.data.count
        this.setState({
          emails: distributionDatas,
          currentPage: pageNumber,
          emailData: null,
          isEmailFormOpen: false,
        })
      } else {
        message.error(res.message)
        this.setState({
          isEmailFormOpen: false,
        })
      }
    } catch {
      message.error('Failed to fetch')
      this.setState({
        isEmailFormOpen: false,
      })
    }
  }

  getGpakSampleData = async () => {
    let urlLink = `distribution-list-gpak/`
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        this.setState({
          emails: userRes.data.results,
        })
      } else {
      }
    } catch (error) {
      message.error('Filter Not Applied')
    }
  }

  render() {
    //table columns
    const emailColumns = [
      {
        title: 'Emails',
        dataIndex: 'emails',
        key: 'emails',
        width: '20%',
        ...this.getColumnSearchProps('emails'),
        render: (text, record) => (
          <Fragment>
            <span className="useremails">{`${text.join(',  ')}`}</span>
          </Fragment>
        ),
      },
    ]
    const columns = [
      {
        title: 'Product Family',
        dataIndex: 'product_family',
        key: 'product_name',
        width: '20%',
        ...this.getColumnSearchProps('product_name'),
      },
      {
        title: 'GPAK Sample',
        dataIndex: 'gpak_sample',
        key: 'distributors',
        width: '20%',
        ...this.getColumnSearchProps('distributors'),
      },
    ]
    //Action button column
    if (isPermission(PERMISSIONS_BY_NAME['Manage Distribution list'])) {
      columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: 60,
        render: (text, record) => (
          <Fragment>
            <Link className="ico_edit">
              <EditOutlined onClick={() => this.setFormStatus(record)} />
            </Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record.id)}
            >
              <Link to="" className="ico_delete">
                <DeleteOutlined />
              </Link>
            </Popconfirm>
          </Fragment>
        ),
      })
    } else if (isPermission(PERMISSIONS_BY_NAME['View Distribution list'])) {
      columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: 60,
        render: (text, record) => (
          <Fragment>
            <Link className="ico_edit">
              <EyeOutlined onClick={() => this.setFormStatus(record)} />
            </Link>
          </Fragment>
        ),
      })
    }

    emailColumns.push({
      title: 'Action',
      dataIndex: 'action',
      width: 60,
      render: (text, record) => (
        <Fragment>
          <Link className="ico_edit">
            <EditOutlined onClick={() => this.setEmailFormStatus(record)} />
          </Link>
        </Fragment>
      ),
    })

    const renderForm = () => {
      if (this.state.isEmailFormOpen) {
        return (
          <div>
            <ProductFamilyEmailForm // Add/Edit form
              distributionData={this.state.emails}
              setFormStatus={this.setEmailFormStatus}
              onChangePageNumber={this.onChangeEmailPageNumber}
              // Can be used in future
              // currentPage={this.state.currentPage}
              // pageLimit={this.state.pageLimit}
            />
          </div>
        )
      }

      if (this.state.isFormOpen) {
        return (
          <div>
            <ProductFamilyForm // Add/Edit form
              distributionData={this.state.currentData}
              setFormStatus={this.setFormStatus}
              onChangePageNumber={this.onChangePageNumber}
              currentPage={this.state.currentPage}
              pageLimit={this.state.pageLimit}
            />
          </div>
        )
      }
    }

    const renderTable = () => {
      return (
        <div className="ManageDistributionTableStyles">
          <div className="ManageDistributionTable_container">
            <div className="Table_top_row">
              <div className="Table_heading">Product Family</div>
              <div className="Table_action_btns">
                {isPermission(
                  PERMISSIONS_BY_NAME['Manage Distribution list']
                ) && (
                  <Button
                    onClick={() => this.setFormStatus('')}
                    size="large"
                    type="primary"
                  >
                    Add New
                  </Button>
                )}
              </div>
            </div>
            <div className="layout_content_box">
              <Table // Distribution table
                className="Table_block_main"
                bordered
                scroll={{ y: 400 }}
                dataSource={this.state.tableData}
                columns={columns}
                loading={this.state.loader}
                rowKey="name"
              />
              <div>
                <h1 className="layout-heading">
                  Distributors For GPAK Samples
                </h1>
                <Table // Distribution table
                  className="Table_block_main"
                  bordered
                  scroll={{ y: 400 }}
                  dataSource={this.state.emails}
                  columns={emailColumns}
                  loading={this.state.loader}
                  rowKey="name"
                />
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.state.loader ? (
      <div className="loader">
        <Spin />
      </div>
    ) : this.state.isEmailFormOpen || this.state.isFormOpen ? (
      <div>{renderForm()}</div>
    ) : (
      <div>
        <div>{renderTable()}</div>
      </div>
    )
  }
}

export default ProductFamilyTable
