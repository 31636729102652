import React, { Component } from 'react'
import moment from 'moment'
import {
  Input,
  Button,
  Form,
  message,
  DatePicker,
  TimePicker,
  Select,
  Spin,
} from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import { HTTPService } from '../../otherServices/apiService'
import {
  trimSpace,
  isPermission,
  isDisabled,
} from '../../otherServices/commonFunction'
import DPLscreening from '../../pages/DPLscreening/DPLscreening'
const { Option } = Select
const { TextArea } = Input

class DPLscreeningForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dplLOgData: props.dplLOgData,
      processLoader: false,
    }
    
  }

  onFinish = async (values) => {
    this.setState({ processLoader: true })
    let data = this.state.dplLOgData

    let dplData = trimSpace(values)
    let res

    try {
      res = await HTTPService({
        method: 'PUT',
        url: `dpl-screening/${data.id ? data.id : this.props.dplLOgData.id}/`,
        data: { ...dplData },
      })
      if (res.success) {
        message.success('Updated Successfully')
        this.props.onChangePageNumber(
          this.props.currentPage,
          this.props.pageLimit
        )
      } else {
        message.error(res.message)
        this.setState({ dplLOgData: dplData })
      }
    } catch {
      message.error('Failed to Update')
      this.setState({ dplLOgData: dplData })
    }

    this.setState({ processLoader: false })
  }

  onFinishFailed = (errors) => {
    
    message.error('Enter the required fields!')
  }

  render() {
    const { dplLOgData = {}, processLoader } = this.state
    const { isAddPart } = this.props
    const userRole = localStorage.getItem('role')
    const validationMessage = 'Maximum field value is '
    return (
      <div className="AddCatalogStyles">
        {processLoader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}

        <div className="AddCatalog_container">
          <div className="AddCatalog__title">Edit DPL Log</div>
          <div className="layout_content_box AddCatalog_whitebox">
            <Form
              className="form_block_main add-catalog-form"
              initialValues={{ ...dplLOgData }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              scrollToFirstError
            >
              <div className="formitem_row">
                <Form.Item label="Web Order / Pulse Order" name="web_order">
                  <Input placeholder="Enter Web Order" size="large" disabled />
                </Form.Item>
                <Form.Item label="Company Name" name="company_name">
                  <Input
                    placeholder="Enter Company Name"
                    size="large"
                    disabled
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item label="Company Country" name="company_country">
                  <Input
                    placeholder="Enter Company Country"
                    size="large"
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Requestor Name" name="requestor_name">
                  <Input
                    placeholder="Enter Requestor Name"
                    size="large"
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Contact Name" name="contact_name">
                  <Input
                    placeholder="Enter Contact Name"
                    size="large"
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Shipto Name" name="shipto_name">
                  <Input
                    placeholder="Enter Shipto Name"
                    size="large"
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Shipto Country" name="shipto_country">
                  <Input
                    placeholder="Enter Shipto Country"
                    size="large"
                    disabled
                  />
                </Form.Item>
                <Form.Item label="DPL Audit" name="dpl_audit">
                  <Input placeholder="Enter DPL Audit" size="large" disabled />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="DPL Name" name="dpl_name">
                  <Input placeholder="Enter DPL Name" size="large" disabled />
                </Form.Item>
                <Form.Item label="DPL Street" name="dpl_street">
                  <Input placeholder="Enter DPL Street" size="large" disabled />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="DPL City" name="dpl_city">
                  <Input placeholder="Enter DPL City" size="large" disabled />
                </Form.Item>
                <Form.Item label="DPL Country" name="dpl_country">
                  <Input
                    placeholder="Enter DPL Country"
                    size="large"
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="DPL Request" name="dpl_request">
                  <TextArea
                    placeholder="Enter DPL Request"
                    autoSize={{ maxRows: 6 }}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="DPL Response" name="dpl_response">
                  <TextArea
                    placeholder="Enter DPL Response"
                    autoSize={{ maxRows: 6 }}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Release',
                    },
                  ]}
                  label="Release"
                  name="release"
                >
                  <Select
                    placeholder="Release"
                    style={{ width: '100%' }}
                    disabled={!isPermission(PERMISSIONS_BY_NAME['Edit DPL'])}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Processed" name="processed">
                  <Input placeholder="Enter Processed" size="large" disabled />
                </Form.Item>
              </div>

              <div className="formitem_action_row" id="bottom">
                <Form.Item>
                  <Button
                    size="large"
                    onClick={() => this.props.setFormStatus('', '')}
                  >
                    Back
                  </Button>
                  {isPermission(PERMISSIONS_BY_NAME['Edit DPL']) && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                    </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
          {/* <div className="fixed_scroll_button">
            <Button type="primary" className="fixed_button"
              size="large" onClick={() => {
                var elmnt = document.getElementById("bottom");

                elmnt.scrollIntoView();
              }}> Scroll to bottom<ArrowDownOutlined /></Button>
          </div> */}
        </div>
      </div>
    )
  }
}

export default DPLscreeningForm
