import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import './DKInventoryReport.scss'
import { withRouter } from 'react-router-dom'
import DKInventoryReportTableEdit from '../../components/DKInventoryReport/DKInventoryReportTableEdit'

const { Content } = Layout

class DKInventoryReport extends Component {
  render() {
    return (
      <div className="CatalogManagementStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>DK Inventory Report</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <DKInventoryReportTableEdit />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default withRouter(DKInventoryReport)