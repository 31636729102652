import React, { useState } from 'react'
import { HTTPService } from '../../otherServices/apiService'
import './DKInventory.scss'
import { Form, Input, Button, message, Table } from 'antd'
const DKInventoryComp = () => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [showTable, setShowTable] = useState(false)
  const handleDkFinish = async (values) => {
    const { part_number } = values

    try {
      setLoading(true)
      const res = await HTTPService({
        url: 'check_dk_inventory/',
        method: 'POST',
        data: { ...values },
      })
      if (res.success) {
        setItems(res.data)
        message.success('Success')
        setShowTable(true)
      } else {
        setItems([])
        message.error(res.message)
      }
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'Quantity Available',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Quantity Required (Buffered)',
      dataIndex: 'buffered_qty',
      key: 'buffered_qty',
    },
    {
      title: 'Can be Fulfilled',
      dataIndex: 'can_be_fulfilled',
      key: 'can_be_fulfilled',
    },
  ]

  return (
    <div className="BusinessRules_container">
      <div className="layout_content_box BusinessRules_whitebox">
        <Form
          onFinish={handleDkFinish}
          className="form_block_main business-rules-form"
        >
          <div className="formitem_row">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter Part Number',
                },
              ]}
              label="Part Number"
              name="part_number"
            >
              <Input placeholder="Part Number" size="large" />
            </Form.Item>
          </div>
          <div className="formitem_row">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter Quantity',
                },
              ]}
              label="Quantity Required"
              name="quantity"
            >
              <Input
                type="number"
                min={0}
                placeholder="Quantity"
                size="large"
              />
            </Form.Item>
          </div>
          <div className="formitem_row">
            <Form.Item name="part_number">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
        {showTable && (
          <Table
            pagination={false}
            dataSource={items}
            columns={columns}
          ></Table>
        )}
      </div>
    </div>
  )
}

export default DKInventoryComp
