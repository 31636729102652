import React, { Component } from 'react'
import { Layout, Breadcrumb } from 'antd'
import './DKInventory.scss'
import { withRouter } from 'react-router-dom'
import DPLscreeningTable from '../../components/DPLscreeningTable/DPLscreeningTable'
import DKInventoryComp from '../../components/DKInventory/DKInventoryComp'
const { Content } = Layout

class DKInventory extends Component {
  render() {
    return (
      <div className="CatalogManagementStyles">
        <DKInventoryComp />
      </div>
    )
  }
}

export default withRouter(DKInventory)
