import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import moment from 'moment'
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  message,
  Spin,
  Space,
} from 'antd'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { HTTPService } from '../../otherServices/apiService'
import {
  DATE_FORMAT,
  CATALOG_SORTING,
  PERMISSIONS_BY_NAME,
  ORDER_SORTING,
} from '../../otherServices/constant'

const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async (e) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {}
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 20,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class CustomerAuditLog extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  //table column properties
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.state = {
      dataSource: null,
      count: 0,
      currentPage: 1,
      tableData: [],
      isFormOpen: false,
      currentData: null,
      loader: false,
      searchText: null,
      filterApplied: null,
      isAddPart: false,
      filterValueArray: [],
      isFilter: false,
      pageLimit: 20,
      filterString: '',
      selectAllExportData: [],
      exportURL: '',
      highlightFilterArray: [],
      selectedLog: null,
      exportAllData: [],
    }
  }

  componentDidMount() {
    this.fetchData(
      this.props &&
        this.props.history.location &&
        this.props.history.location.search
    )
    this.setState({ searchText: this.props.history.location.search })
  }

  //function to fetch table data
  fetchData = async (searchValue = '') => {
    this.setState({ loader: true, highlightFilterArray: [] })
    let urlLink
    if (
      this.props &&
      this.props.history.location &&
      this.props.history.location.search &&
      this.state.searchText != null
    ) {
      urlLink = `audit_logs/customer-channel-audit-logs/${this.props.history.location.search}`
    } else {
      urlLink = `audit_logs/customer-channel-audit-logs/${searchValue}`
    }
    try {
      let res = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (res.success) {
        let dplData = []
        for (let i of res.data.results) {
          dplData.push({ ...i, key: i.id })
        }
        this.setState({
          dataSource: dplData,
          count: res.data.count,
          loader: false,
          searchText: searchValue || null,
          isFilter: false,
          filterValueArray: [],
        })
        message.success('Fetched Successfully')
      } else {
        message.error(res.message)
        this.setState({
          dataSource: [],
          count: 0,
          loader: false,
          searchText: searchValue || null,
        })
      }
    } catch {
      this.setState({
        dataSource: [],
        count: 0,
        loader: false,
        searchText: searchValue || null,
      })
      message.error('Failed to Fetch')
    }
  }

  //function to fetch table data
  async getOrderList(searchValue = '', currentPage = 1) {
    let offset = (currentPage - 1) * this.state.pageLimit
    let urlLink = `audit_logs/customer-channel-audit-logs/${
      searchValue && searchValue
    }`
    this.setState({ loader: true, highlightFilterArray: [] })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }
        this.setState({
          dataSource: orders,
          loader: false,
          count: userRes.data.count,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
          filterValueArray: [],
          isFilter: false,
          selectedRoreOrder: [],
        })
        message.success('Fetched Successfully')
      } else {
        this.setState({
          tableData: [],
          loader: false,
          count: 0,
          searchText: searchValue || null,
          currentPage,
        })
        message.error(userRes.message)
      }
    } catch (error) {
      message.error('Response Failed')
      this.setState({
        tableData: [],
        loader: false,
        count: 0,
        searchText: searchValue || null,
        currentPage,
      })
    }
  }

  //function to fetch filtered data
  async getFilteredData(searchValue = '', currentPage = 1) {
    let urlLink
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.search
    ) {
      urlLink = `audit_logs/customer-channel-audit-logs/${this.props.history.location.search}&&${searchValue}`
    } else {
      urlLink = `audit_logs/customer-channel-audit-logs/?${searchValue}`
    }

    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }

        this.setState({
          dataSource: orders,
          loader: false,
          count: userRes.data.count,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
        })
        message.success('Filter Applied')
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      message.error('Filter Not Applied')
    }
  }

  //search fumnction
  onSearch = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      let stringSplit = value.split('=')
      if (i.includes(stringSplit[0])) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    arrayOfFilter.push(value)
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      filterString: `${arrayOfFilter.join('&&')}`,
      highlightFilterArray: highlightArray,
    })
    this.getFilteredData(`${arrayOfFilter.join('&&')}`)
  }

  //function to reset applied filters
  onFilterReset = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      if (i.includes(value)) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      highlightFilterArray: highlightArray,
    })
    if (arrayOfFilter.length == 0) {
      this.fetchData('')
    } else {
      this.setState({ filterString: `${arrayOfFilter.join('&&')}` })
      this.getFilteredData(`${arrayOfFilter.join('&&')}`)
    }
  }

  //function for search functionaliity in table columns
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div className="search_filterDropdown">
        <Input
          className="search_input"
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            clearFilters()
            this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
          }}
        />
        <Space className="btns">
          <Button
            type="primary"
            onClick={() => {
              clearFilters()
              this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
            }}
            icon={<SearchOutlined />}
            size="medium"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              // clearFilters()
              this.onFilterReset(`${dataIndex}`)
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#3653AA' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#fafafa', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  handleChange = (value, keyName, record, isDate = false) => {
    if (isDate) {
      record[keyName] = moment().format(DATE_FORMAT)
      return
    }
    record[keyName] = value
  }

  //function to toggle flag to open Add/Edit form
  setFormStatus = (dplLOgData) => {
    this.setState({ isAddPart: true })

    this.setState({
      isFormOpen: !this.state.isFormOpen,
      currentData: dplLOgData ? dplLOgData : null,
      searchText: `?web_order=${dplLOgData.web_order}`,
    })
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.id === item.id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData,
    })
  }

  //function to fetch data fot table on page change
  onChangePageNumber = async (pageNumber, PAGE_LIMIT) => {
    this.setState({ loader: true, pageLimit: PAGE_LIMIT })
    let offset = (pageNumber - 1) * PAGE_LIMIT
    try {
      let catelogRes
      if (this.state.searchText == null) {
        catelogRes = await HTTPService({
          method: 'GET',
          url: `audit_logs/customer-channel-audit-logs/?${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      } else {
        catelogRes = await HTTPService({
          method: 'GET',
          url: `audit_logs/customer-channel-audit-logs/${this.state.searchText}&${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      }

      if (catelogRes.success) {
        let catelogs = [],
          count = 0
        for (let catelog of catelogRes.data.results) {
          catelogs.push({ ...catelog, key: catelog.id })
        }

        count = catelogRes.data.count
        this.setState({
          dataSource: catelogs,
          count,
          currentPage: pageNumber,
          currentData: null,
          isFormOpen: false,
          loader: false,
        })
      } else {
        message.error(catelogRes.message)
        this.setState({
          isFormOpen: false,
          loader: false,
        })
      }
    } catch {
      message.error('Failed to fetch')
      this.setState({
        isFormOpen: false,
        loader: false,
      })
    }
  }

  //function to display total item
  showTotal = () => {
    return `Total ${this.state.count}`
  }

  goBack() {
    this.props.history.goBack()
  }
  render() {
    this.columns = [
      {
        title: 'Column Name',
        dataIndex: 'column_name',
        width: 200,
        ...this.getColumnSearchProps('column_name'),
        className: this.state.highlightFilterArray.includes('column_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Old Value',
        dataIndex: 'old_value',
        width: 200,
        ...this.getColumnSearchProps('old_value'),
        className: this.state.highlightFilterArray.includes('old_value')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'New Value',
        dataIndex: 'new_value',
        ...this.getColumnSearchProps('new_value'),
        width: 200,
        className: this.state.highlightFilterArray.includes('new_value')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Modified Date',
        dataIndex: 'modified_date',
        width: 200,
        ...this.getColumnSearchProps('modified_date'),
        className: this.state.highlightFilterArray.includes('modified_date')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Modified Time',
        dataIndex: 'modified_time',
        width: 200,
        ...this.getColumnSearchProps('modified_time'),
        className: this.state.highlightFilterArray.includes('modified_time')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'User Email',
        dataIndex: 'user_email',
        width: 200,
        ...this.getColumnSearchProps('user_email'),
        className: this.state.highlightFilterArray.includes('user_email')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti Name',
        dataIndex: 'disti_name',
        width: 200,
        ...this.getColumnSearchProps('disti_name'),
        className: this.state.highlightFilterArray.includes('disti_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: 200,
        ...this.getColumnSearchProps('action'),
        className: this.state.highlightFilterArray.includes('action')
          ? 'filtered_text'
          : '',
      },
    ]
    //function to select records/rows of table
    const { dataSource, currentPage, count } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })

    const renderTable = () => {
      const rowSelection = {
        selectedRowKeys: this.state.selectedLog,
        onChange: (selectedLogs, selectedRows) => {
          this.setState({ selectedLog: selectedLogs })
        },
      }

      return (
        <div className="DPLsreeningTableStyles">
          <div className="DPLscreeningStyleEdit_container">
            <div className="Table_top_row">
              <div className="Table_heading">Customer Channel Audit Logs</div>
              <div className="Table_action_btns">
                <div className="table_search_block">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.getOrderList('')}
                  >
                    Reset
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => this.props.setAuditLog('', '')}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
            <div className="layout_content_box CatalogTableEdit_whitebox">
              <Table
                className="Table_block_main"
                components={components}
                scroll={{ x: 1500, y: 500 }}
                rowClassName={() => 'editable-row'}
                bordered
                rowSelection={{
                  ...rowSelection,
                }}
                dataSource={dataSource || []}
                columns={columns}
                pagination={{
                  onChange: this.onChangePageNumber,
                  current: currentPage,
                  total: count,
                  defaultPageSize: this.state.pageLimit,
                  showSizeChanger: true,
                  onShowSizeChange: this.onChangePageNumber,
                  showTotal: this.showTotal,
                }}
                loading={this.state.loader}
                rowKey="id"
              />
            </div>
          </div>
        </div>
      )
    }

    return <div>{renderTable()}</div>
  }
}

export default withRouter(CustomerAuditLog)
