import React, { Component } from 'react'
import { Layout, Breadcrumb, message, Spin, Tabs } from 'antd'
import './SFTPSettings.scss'
import { withRouter } from 'react-router-dom'
import HistoryTable from '../../components/HistoryTable/HistoryTable'
import { HTTPService } from '../../otherServices/apiService'
import moment from 'moment'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  PAGE_LIMIT,
  USER_SORTING,
} from '../../otherServices/constant'
import ImportSampleOrderForSFTP from '../ImportSampleOrderForSFTP/ImportSampleOrderForSFTP'
const { Content } = Layout

const { TabPane } = Tabs

class SFTSSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      loader: false,
      titleName: 'GIS Export History',
      users: [],
      isDistributionData: false,
    }
  }

  componentDidMount() {
    this.fetchTableData()
    this.fetDistributionList()
  }

  //function fetch distribution list for form
  async fetDistributionList() {
    let userRes
    try {
      userRes = await HTTPService({
        method: 'GET',
        url: `distribution-list/?ordering=-id&limit=${PAGE_LIMIT}&offset=0&${USER_SORTING}`,
      })
      if (userRes.success) {
        this.setState({ users: userRes.data.results, isDistributionData: true })
      } else {
        this.setState({ isDistributionData: false })
      }
    } catch (ex) {
      this.setState({ isDistributionData: false })
    }
  }

  //function to fetch table data
  async fetchTableData(val) {
    this.setState({ loader: true })
    let formKey = 'GISExport'
    let titleName = 'GIS Export History'
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: `order-history/?search=${formKey}&ordering=-id`,
      })
      if (userRes.success) {
        // message.success(userRes.message)
        this.setState({
          tableData: userRes.data.results,
          loader: false,
          titleName,
        })
      } else {
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Failed load data', error)
    }
  }

  render() {
    let fetchedData = this.state.tableData
    fetchedData.map((i) => {
      i.date = moment(i.created_at).format(DATE_FORMAT)
      i.time = moment(i.created_at).format(TIME_FORMAT)
      return i
    })

    return (
      <div className="FTSSettingsStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>SFTP Settings</Breadcrumb.Item>
              </Breadcrumb>
              <Tabs
                className="FTSSettings_Tabs"
                defaultActiveKey="1"
                onChange={(val) => {
                  this.fetchTableData(val)
                }}
              >
                <TabPane tab="GIS Export" key="1">
                  <ImportSampleOrderForSFTP
                    sftp_purpose="GISExport"
                    tabTitle="GIS Export"
                    users={this.state.users}
                  />
                </TabPane>
              </Tabs>
              {this.state.loader && this.state.isDistributionData ? (
                <div className="loader">
                  {' '}
                  <Spin />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <HistoryTable
                    titleName={this.state.titleName}
                    data={fetchedData}
                    row
                  />
                </div>
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default withRouter(SFTSSettings)
