import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { BrowserRouter, Switch } from 'react-router-dom'
import { routes } from './routes'
import { Spin } from 'antd'
import 'antd/dist/antd.css'
import './App.scss'
import { AppLayout } from './container/AppLayout'
import { isPermission, isRoutePermitted } from './otherServices/commonFunction'
import { PERMISSIONS_BY_NAME } from './otherServices/constant'
import NoAccessPage from './pages/NoAccessPage/NoAccessPage'
import AutoLagoutTimer from './components/HOC/AutoLogoutTimer'
import SampleOrder from './pages/SampleOrder/SampleOrder'
import UserManagement from './pages/UserManagement/UserManagement'
import CatalogManagement from './pages/CatalogManagement/CatalogManagement'
import BusinessRules from './pages/BusinessRules/BusinessRules'
import DPLscreening from './pages/DPLscreening/DPLscreening'
import EditProfile from './pages/EditProfile/EditProfile'
import ManageDistribution from './pages/ManageDistribution/ManageDistribution'
import FTSSettings from './pages/FTSSettings/FTSSettings'
import SFTPSettings from './pages/SFTPSettings/SFTPSettings'
import CustomerChannel from './pages/CustomerChannel/CustomerChannel'
import UserRoles from './pages/UserRoles/UserRoles'
import DKInventory from './pages/DkInventory/DKInventory'
import DKInventoryReport from './pages/DKInventoryReport/DKInventoryReport'
import EUSHoldRelease from './pages/EUSHoldRelease/EUSHoldRelease'
import ERPOrderNumber from './pages/ERPOrderNumber/ERPOrderNumber'
import SampleOrderAudit from './pages/SampleOrderAudit/SampleOrderAudit'
import ProductFamily from './pages/ProductFamily/ProductFamily'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to signin page/NoAccessPage
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('accessToken') && isRoutePermitted(rest.path) ? (
          <AppLayout>
            <AutoLagoutTimer ComposedClass={Component}></AutoLagoutTimer>
            {/* <Component {...props} /> */}
          </AppLayout>
        ) : localStorage.getItem('accessToken') ? (
          <Redirect to={routes.NoAccessPage.path} />
        ) : (
          <Redirect to={routes.login.path} />
        )
      }
    />
  )
}

class App extends Component {
  render() {
    return (
      //routes
      <div className="App">
        <Suspense
          fallback={
            <div className="loader">
              <Spin size="large" />
            </div>
          }
        >
          <BrowserRouter>
            <Switch>
              <Router>
                {/* <Route  {...routes.login}/>
                <PrivateRoute path= '/sampleorder' component={SampleOrder} />
                <PrivateRoute {...routes.usermanagement} />
                <PrivateRoute {...routes.catalogmanagement} />
                <PrivateRoute {...routes.businessrules} />
                <PrivateRoute {...routes.dplScreening} />
                <PrivateRoute {...routes.editprofile} />
                <PrivateRoute {...routes.managedistribution} />
                <PrivateRoute {...routes.ftpsettings} />
                <PrivateRoute {...routes.customerchannel} />
                <PrivateRoute {...routes.userroles} />
                <PrivateRoute {...routes.editprofile} />
                <Route {...routes.forgotpassword} />
                <PrivateRoute {...routes.yourorders} /> */}

                <Route {...routes.login} />
                <PrivateRoute path="/sampleorder" component={SampleOrder} />

                <PrivateRoute
                  path="/usermanagement"
                  component={UserManagement}
                />
                <PrivateRoute
                  path="/catalogmanagement"
                  component={CatalogManagement}
                />
                <PrivateRoute path="/businessrules" component={BusinessRules} />
                <PrivateRoute path="/dplScreening" component={DPLscreening} />
                <PrivateRoute path="/dkinventory" component={DKInventory} />

                <PrivateRoute path="/editprofile" component={EditProfile} />
                <PrivateRoute
                  path="/managedistribution"
                  component={ManageDistribution}
                />
                <PrivateRoute path="/ftpsettings" component={FTSSettings} />
                <PrivateRoute path="/sftpsettings" component={SFTPSettings} />
                <PrivateRoute
                  path="/customerchannel"
                  component={CustomerChannel}
                />
                <PrivateRoute path="/userroles" component={UserRoles} />
                <PrivateRoute path="/editprofile" component={EditProfile} />
                <PrivateRoute path="/dkreport" component={DKInventoryReport} />
                <PrivateRoute
                  path="/eusholdrelease"
                  component={EUSHoldRelease}
                />
                <PrivateRoute
                  path="/erpordernumber"
                  component={ERPOrderNumber}
                />
                <PrivateRoute path="/productfamily" component={ProductFamily} />
                <PrivateRoute
                  path="/sampleOrderAudit"
                  component={SampleOrderAudit}
                />

                <Route {...routes.forgotpassword} />
                <PrivateRoute {...routes.yourorders} />

                {/* <Route
                  render={(props) => {
                    if (permissions&&permissions.length) {
                      props.history.push(routes.NoAccessPage.path)
                    }
                  }}
                /> */}
                <Route
                  render={(props) => {
                    if (props.location.pathname === '/') {
                      if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View User Management']
                        )
                      ) {
                        props.history.push(routes.sampleorder.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(PERMISSIONS_BY_NAME['View Catalog import'])
                      ) {
                        props.history.push(routes.catalogmanagement.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(PERMISSIONS_BY_NAME['View Sample Order'])
                      ) {
                        props.history.push(routes.sampleorder.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View User Management']
                        )
                      ) {
                        props.history.push(routes.usermanagement.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(PERMISSIONS_BY_NAME['View Business Rules'])
                      ) {
                        props.history.push(routes.businessrules.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View Distribution list']
                        )
                      ) {
                        props.history.push(routes.managedistribution.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(PERMISSIONS_BY_NAME['View FTP settings'])
                      ) {
                        props.history.push(routes.ftpsettings.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View Customer channel']
                        )
                      ) {
                        props.history.push(routes.customerchannel.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View User Role permissions']
                        )
                      ) {
                        props.history.push(routes.userroles.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View User Role permissions']
                        )
                      ) {
                        props.history.push(routes.sampleorder.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(PERMISSIONS_BY_NAME['View DPL'])
                      ) {
                        props.history.push(routes.dplScreening.path)
                      } else if (
                        localStorage.getItem('accessToken') &&
                        isPermission(
                          PERMISSIONS_BY_NAME['View DK Inventory Report']
                        )
                      ) {
                        props.history.push(routes.dkInventoryReport.path)
                      } else props.history.push(routes.login.path)
                    }
                  }}
                />
              </Router>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </div>
    )
  }
}

export default App
