import React from 'react'
import { Layout, Breadcrumb } from 'antd'
import './UserRoles.scss'
import UserRoleTable from '../../components/UserRoleTable/UserRoleTable'
const { Content } = Layout

class UserRoles extends React.Component {
  render() {
    return (
      <div className="UserRolesStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>UserRoles</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
              </Breadcrumb>
              <UserRoleTable />
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default UserRoles
