import React, { Component } from 'react'
import { Layout, Form, Input, Button, Breadcrumb, Select, Upload, DatePicker, TimePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import './ImportSampleOrderApproval.scss'
import HistoryTable from '../../components/HistoryTable/HistoryTable'
import { NavLink } from 'react-router-dom'
const { Content } = Layout;

const { Option } = Select;

class ImportSampleOrderApproval extends Component {
  render() {
    return (
      <div className="ImportSampleOrderApprovalStyles">
              <div className="ImportSampleOrderApproval_container">
                <div className="ImportSampleOrderApproval__title">Import Sample Order Approval</div>
                <div className="layout_content_box ImportSampleOrderApproval_whitebox">
                  <Form
                    className="form_block_main import-sample-order-approval-form"
                    initialValues={{ remember: true }}
                  >
                    <div className="formitem_row">
                      <Form.Item
                        label="Sample Order Approval Import"
                      >
                        <Input
                          placeholder="sample order approval import"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Sample Order Approval Archive"
                      >
                        <Input
                          placeholder="sample order approval archive"
                          size="large"
                        />
                      </Form.Item>
                    </div>
                    <div className="formitem_row">
                      <Form.Item label="Username" rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}>
                        <Input placeholder="John Doe" size="large" />
                      </Form.Item>
                      <Form.Item label="Email">
                        <Input placeholder="JohnDoe@yopmail.com" size="large" />
                      </Form.Item>
                    </div>
                    <div className="formitem_row">
                      <Form.Item label="Password">
                        <Input.Password placeholder="********" size="large" />
                      </Form.Item>
                      <Form.Item name="date-picker" label="Start Date">
                        <DatePicker placeholder="YYYY-MM-DD" size="large" />
                      </Form.Item>
                    </div>
                    <div className="formitem_row">
                      <Form.Item name="time-picker" label="Start Time">
                        <TimePicker placeholder="HH:mm:ss" size="large" />
                      </Form.Item>
                      <Form.Item name={['user', 'introduction']} label="Frequency">
                        <Select defaultValue="5 Min" style={{ width: '100%' }}>
                          <Option value="5 Min">5 Min</Option>
                          <Option value="10 Min">10 Min</Option>
                          <Option value="15 Min">15 Min</Option>
                          <Option value="20 Min">20 Min</Option>
                          <Option value="25 Min">25 Min</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="formitem_upload_row">
                      <Form.Item label="Sample Order Approval File">
                        <Form.Item name="dragger" valuePropName="fileList">
                          <Upload.Dragger name="files" action="/upload.do">
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Form.Item>
                    </div>
                    <div className="formitem_action_row">
                      <Form.Item>
                        <Button size="large">
                          Back
                          </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          size="large"
                        >
                          Save
                         </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
    )
  }
}

export default ImportSampleOrderApproval





